// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDQK_8nVvgUJtZtgNnXQR9lEqPRv6EnP8Y",
    authDomain: "iblite-c1ee9.firebaseapp.com",
    databaseURL: "https://iblite-c1ee9-default-rtdb.firebaseio.com",
    projectId: "iblite-c1ee9",
    storageBucket: "iblite-c1ee9.appspot.com",
    messagingSenderId: "256977768440",
    appId: "1:256977768440:web:56f76e404b9f386688500d",
    measurementId: "G-EZ33E16KK0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firestore = getFirestore(app);
export {firestore, analytics};