import { createContext, useContext, useEffect, useState } from "react";
import {data} from './../data/questions'



type QuestionProps = {
    id:number, 
    prompt:string, 
    topic:string, 
    solution?:string, 
    solutionSteps?:Array<Array<string>>, 
    isMultiStep?:boolean,
    saved?: boolean
}

const QuestionsContext = createContext<{
    questions:QuestionProps[],
    setQuestions:(q:QuestionProps[])=>void
}|null>(null);

export function QuestionsProvider(props: any){
    const init = {
        id:1, 
        prompt:"What are the three financial statements and why they are important", 
        solution:"Cash Flow",  
        isMultiStep:true,

        solutionSteps:[[
            "Balance Sheets", "show a company’s assets and liabilities, including shareholder equity, debt, and accounts payable"],["title", "description"]],
        topic:"financial statements"
    }
    
    const [questions, setQuestions] = useState<QuestionProps[]>(data)
    useEffect(()=>{
       
            const practiceQuestions = data;
            const storedQuestionIds = localStorage.getItem("IBlite");
            const questionIds =  storedQuestionIds ?  JSON.parse(storedQuestionIds) : [""];
            //add saved prop to questions that are saved
            const savedProppedQuestions = practiceQuestions.map((q: QuestionProps)=> {
                if (questionIds.includes(q.id)){
                    q.saved=true
                } else {
                    q.saved=false
                }
                return q;
             });
             setQuestions(savedProppedQuestions);
      
    },[])
    return(
        <QuestionsContext.Provider value={{questions, setQuestions}}>
            {props.children}
        </QuestionsContext.Provider>
    )
}

export default function useQuestions(){
    return useContext(QuestionsContext);
}