import React from 'react';

import { Box, LinearProgress, Stack, Typography } from '@mui/material';




export default function LoadingPage(){
    return (
        <Box sx={{flex:1, display:"flex", textAlign:"center", height:"100vh", alignItems:"center", justifyContent:"center"}}>
           <Box style={{minWidth:240}}>
                <Typography color="primary" sx={{pb:1}}>Loading</Typography>
                <LinearProgress />
            </Box>
        </Box>
    )
}