import React, { ReactNode } from "react";
import { Box, Tabs, Tab, Typography, Paper } from "@mui/material"
import { AuthedContactUsForm, ContactUsForm } from "../components/forms/HelpContactForm";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`help-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3, m:"0 auto" }}>
                {children}
            </Box>
        )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `help-tab-${index}`,
        'aria-controls': `help-tabpanel-${index}`,
    };
}
  


const PageContent = ({children}:{children:ReactNode}) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
    
            <div style={{ maxWidth:"600px", margin:"0 auto"}}>
                <Typography variant="h4" sx={{m:"24px auto"}}>Contact Us</Typography>
                <Paper sx={{ borderBottom: 1, borderColor: 'divider', m:"0 auto" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="help tabs" variant="fullWidth" sx={{alignItems:"center"}}>
                            <Tab label="Contact Form" {...a11yProps(0)} />
                        </Tabs>
                    
                    <TabPanel value={value} index={0}>
                        {children}
                    </TabPanel>
                </Paper>
            </div>
        
    )
}

export const HelpPage = () => {

    return (
        <PageContent>
            <ContactUsForm/>
        </PageContent>
    )
}

export const AuthedHelpPage = () => {
    return (
        <PageContent>
            <AuthedContactUsForm/>
        </PageContent>
    )
}