
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

export default function DisclaimerModal() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
        <>
        <Typography variant="body2" color="text.secondary" align="center" sx={{pt:"32px"}} >
                    <Link color={"inherit"} href="/" onClick={(e)=>{
                        e.preventDefault();
                        handleClickOpen();
                    }}>
                    Disclaimer
                    </Link>{' '}
                
        </Typography>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"Disclaimer"}
            </DialogTitle>
        
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                IBlite may contain copyrighted material, the use of which may not have bee specifically authorized by the 
                copyright owner. This material is available in effort to educate others. Questions are crowdsourced 
                from 3rd-party, publicly available sources. IBlite does not claim ownership or accuracy of questions.
                Copyright Disclaimer under section 107 of the Copyright Act 1976: allowance is made for “fair use” for purposes such as teaching and education.
            </DialogContentText>
            <br></br>
            <DialogContentText>
                While we edeavour to keep the information up to date and correct, IBlite makes no representations or
                warranties of any kind, express or implied, about the completeess, accuracy, reliability, 
                suitability, or availability with respect to the website or the information, services, and graphics 
                contained on the website for any purpose. Any reliance on such material is at your own risk.
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} autoFocus>
                OK
            </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}