
import './App.css';
import './firebaseConfig';
import {
    BrowserRouter as Router,
    Route,
    Routes,
	Navigate,
	Outlet
} from "react-router-dom";
import { AuthedPracticePage, PracticePage } from './routes/practice';
import { AuthedHelpPage, HelpPage } from './routes/help';
import SignInPage from './routes/SignInPage';
import SignUpPage from './routes/SignUpPage';
import useAuth, { AuthProvider } from './hooks/useAuth';
import { AuthedQuestionActionsProvider, QuestionActionsProvider } from './hooks/useQuestionActions';
import { AuthedDrawer, UnAuthedDrawer } from './components/drawer';
import ForgotPasswordPage from './routes/ForgotPasswordPage';
import PasswordReset from './routes/PasswordReset';
import  {Helmet, HelmetProvider} from 'react-helmet-async';

const RequireAuth = () => {
	const {user} = useAuth()
	
	if (user){
		return (<AuthedDrawer><Outlet/></AuthedDrawer>)
	}
	return <UnAuthedDrawer><Navigate to="/" replace/></UnAuthedDrawer>
}

const RedirectAuth = () => {
	const {user} = useAuth();
	
	if (user){
		return (
			<AuthedDrawer><Navigate to="/home" replace/></AuthedDrawer>
		)
	} else {
		return (<UnAuthedDrawer><Outlet/></UnAuthedDrawer>)
	}
}

const MetaData = () => {
	return (
		<Helmet>
			<title>IBlite</title>
			<meta name="description" content='Improve your investment banking technical knowledge with IBlite. Use flashcards to learn commonly asked interview technical questions and track your progress along the way.'/>
		</Helmet>
	)
}

function App() {
	
    return (
		<HelmetProvider>
			<Router>
				<MetaData/> 
				<AuthProvider>

					<Routes>
						<Route element={<RedirectAuth/>} >
							<Route index path="/" element={<QuestionActionsProvider><PracticePage/></QuestionActionsProvider>}/>
							<Route path="/signIn" element={<SignInPage/>}/>
							<Route path="/signUp" element={<SignUpPage/>}/>
							<Route path="/forgotPassword" element={<ForgotPasswordPage/>}/>
							<Route path="/reset" element={<PasswordReset/>}/>
							<Route path="/help" element={<HelpPage/>}/>
						</Route>
						<Route element={<RequireAuth/>}>
							<Route index path="/home" element={<AuthedQuestionActionsProvider><AuthedPracticePage/></AuthedQuestionActionsProvider>}/>
							<Route path="/contact" element={<AuthedHelpPage/>}/>
						</Route>
						
					</Routes>
				
				</AuthProvider>
			</Router> 
		</HelmetProvider>
    );
}

export default App;
