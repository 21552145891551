import { Button, Container, Link, Stack, Typography } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import useAuth from '../hooks/useAuth';
import useQuestionActions, { useAuthedQuestionActions } from '../hooks/useQuestionActions';



export const AuthedMarkActions = ({id}:{id:number}) => {
    const questionActions = useAuthedQuestionActions();
    if (!questionActions) return null;
    const {status, markIncorrect, markCorrect} = questionActions;
    return (
        <Stack  direction="row" sx={{mx:"24px auto"}}>
            <Button 
                variant={status[id]==="correct" ? "contained" :"text"}
                sx={{ml:"auto", mr:2}} startIcon={<CheckIcon/>} color="success"
                onClick={()=>markCorrect(id)}
            >Correct</Button>
            <Button 
                variant={status[id]==="incorrect" ? "contained" :"text"}
                sx={{mr:"auto", ml:2}} startIcon={<CloseIcon/>} color="error"
                onClick={()=>markIncorrect(id)}
            >Incorrect</Button>
        </Stack>
    );
}

const UnAuthedMarkActions = ({id}:{id:number}) => {
    const questionActions= useQuestionActions();
    if (!questionActions) return null;
    const {status, markIncorrect, markCorrect} = questionActions;
    return (
        <>
            <Stack  direction="row" sx={{mx:"24px auto"}}>
            <Button 
                variant={status[id]==="correct" ? "contained" :"text"}
                sx={{ml:"auto", mr:2}} startIcon={<CheckIcon/>} color="success"
                onClick={()=>markCorrect(id)}
            >Correct</Button>
            <Button 
                variant={status[id]==="incorrect" ? "contained" :"text"}
                sx={{mr:"auto", ml:2}} startIcon={<CloseIcon/>} color="error"
                onClick={()=>markIncorrect(id)}
            >Incorrect</Button>
            </Stack>
            <Stack  direction="row" sx={{mx:"24px auto"}}>
                <Link href="signIn" variant='body2' sx={{mx:"auto", width:"100%", textAlign:"center"}} color="#808080">sign in to save your results</Link>
            </Stack>
        </>
              
    );
}


export default function MarkSolution(props:{id:number}){
    const {user} = useAuth();
    
    if (user) {
        return (
            <AuthedMarkActions {...props}/>
        );
    }    
    
    return (
        <UnAuthedMarkActions  {...props}/>   
    );
}