import React, { useState } from "react";
import {TextField, Button, Box} from "@mui/material"
import { SnackBar } from "../snackbar";
import { validEmailRegex } from "../../helpers/validation";
import { addDoc, collection } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import useAuth from "../../hooks/useAuth";

export const ContactUsForm = () => {
    const initErr = {msg:"", state:false}
    const formRef = React.useRef<HTMLFormElement>(null);
    const [formState, setFormState] = useState({
        name:{value:"", error:{msg:"", state:false}},
        email:{value:"", error:{msg:"", state:false}},
        message:{value:"", error:{msg:"", state:false}},
    })

    const [snackbarState, setSnackbarState] = useState(false);
    const [errorState, setErrorState] = useState({state:false, msg:""});

    const validate = () => {
        const {name, email, message} = formState;
        var nameErr = initErr;
        var messageErr = initErr;
        var emailErr = initErr;
        
        if (name.value.length < 5){
            nameErr = {msg:"too short: must be atleast 5 characters", state:true};
            //setFormState({...formState, name:{...formState.name, error:{msg:"too short: must be atleast 5 characters", state:true}}})
        }
        if (!validEmailRegex.test(email.value)){
            emailErr = {msg:"invalid email", state:true};
            //setFormState({...formState, email:{...formState.email, error:{msg:"invalid email", state:true}}})
        }
        if (message.value.length < 2){
            messageErr = {msg:"too short: must be atleast 2 characters", state:true};
            //setFormState({...formState, name:{...formState.name, error:{msg:"too short: must be atleast 5 characters", state:true}}})
        }

        if (nameErr.state || messageErr.state || emailErr.state){
            setSnackbarState(true);
            setErrorState({state:true, msg:"Form Errors Found"})
        } else {
            addDoc(collection(firestore,"contacts"), {
                name:name.value, 
                email:email.value, 
                message:message.value
            }).then(() => {
              
                console.log("submitted")
                setErrorState({state:false, msg:"Form Submitted"})
                setSnackbarState(true)
                if (formRef.current){
                    formRef?.current.reset();
                }
                    
            }).catch((err) => {
                console.log(err)
                setSnackbarState(true);
                setErrorState({state:true, msg:"Something went wrong. Could not submit form."})
            });
        }

        setFormState({...formState, 
            name: {
                ...formState.name, 
                error:nameErr
            },
            email: {
                ...formState.email, 
                error:emailErr
            },
            message: {
                ...formState.message, 
                error:messageErr
            },
        })
        
    }

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        validate();
    }

    const closeSnackBar = () => setSnackbarState(false);

    
    return (
        <form onSubmit={handleFormSubmit} ref={formRef} >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <TextField error={formState.name.error.state} helperText={formState.name.error.msg} 
                    onChange={(e)=>setFormState({...formState, name:{...formState.name, value:e.target.value}})} 
                    label="Full Name" variant="outlined" name="name" size="small" fullWidth/>
                <TextField error={formState.email.error.state} helperText={formState.email.error.msg} 
                    onChange={(e)=>setFormState({...formState, email:{...formState.email, value:e.target.value}})} 
                    margin="dense" label="Email" variant="outlined" name="email" type="email" size="small" fullWidth/>
                <TextField error={formState.message.error.state} helperText={formState.message.error.msg} 
                    onChange={(e)=>setFormState({...formState, message:{...formState.message, value:e.target.value}})} 
                    margin="dense" label="Message" variant="outlined" name="message" multiline size="small" fullWidth/>
                <Button type="submit">Submit</Button>
            </Box>
            {
                errorState.state?
                <SnackBar msg={errorState.msg} open={snackbarState} handleClose={closeSnackBar} severity="error"/>:
                <SnackBar msg={errorState.msg} open={snackbarState} handleClose={closeSnackBar} severity="success"/>
            }

        </form>
    )
}

export const AuthedContactUsForm = () => {
    const initErr = {msg:"", state:false}
    const formRef = React.useRef<HTMLFormElement>(null);
    const [formState, setFormState] = useState({
        name:{value:"", error:{msg:"", state:false}},
        message:{value:"", error:{msg:"", state:false}},
    })

    const {user} = useAuth();

    const [snackbarState, setSnackbarState] = useState(false);
    const [errorState, setErrorState] = useState({state:false, msg:""});

    const validate = () => {
        const {name, message} = formState;
        var nameErr = initErr;
        var messageErr = initErr;
        var emailErr = initErr;
        
        if (name.value.length < 5){
            nameErr = {msg:"too short: must be atleast 5 characters", state:true};
            //setFormState({...formState, name:{...formState.name, error:{msg:"too short: must be atleast 5 characters", state:true}}})
        }
        if (message.value.length < 2){
            messageErr = {msg:"too short: must be atleast 2 characters", state:true};
            //setFormState({...formState, name:{...formState.name, error:{msg:"too short: must be atleast 5 characters", state:true}}})
        }

        if (nameErr.state || messageErr.state || emailErr.state){
            setSnackbarState(true);
            setErrorState({state:true, msg:"Form Errors Found"})
        } else {
            addDoc(collection(firestore,"contacts"), {
                name:name.value, 
                email:user?.email,
                uid:user?.uid,
                message:message.value
            }).then(() => {
              
                console.log("submitted")
                setErrorState({state:false, msg:"Form Submitted"})
                setSnackbarState(true)
                if (formRef.current){
                    formRef?.current.reset();
                }
                    
            }).catch((err) => {
                console.log(err)
                setSnackbarState(true);
                setErrorState({state:true, msg:"Something went wrong. Could not submit form."})
            });
        }

        setFormState({...formState, 
            name: {
                ...formState.name, 
                error:nameErr
            },
            message: {
                ...formState.message, 
                error:messageErr
            },
        })
        
    }

    const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        validate();
    }

    const closeSnackBar = () => setSnackbarState(false);

    
    return (
        <form onSubmit={handleFormSubmit} ref={formRef} >
            <Box sx={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <TextField error={formState.name.error.state} helperText={formState.name.error.msg} 
                    onChange={(e)=>setFormState({...formState, name:{...formState.name, value:e.target.value}})} 
                    label="Full Name" variant="outlined" name="name" size="small" fullWidth/>
                
                <TextField error={formState.message.error.state} helperText={formState.message.error.msg} 
                    onChange={(e)=>setFormState({...formState, message:{...formState.message, value:e.target.value}})} 
                    margin="dense" label="Message" variant="outlined" name="message" multiline size="small" fullWidth/>
                <Button type="submit">Submit</Button>
            </Box>
            {
                errorState.state?
                <SnackBar msg={errorState.msg} open={snackbarState} handleClose={closeSnackBar} severity="error"/>:
                <SnackBar msg={errorState.msg} open={snackbarState} handleClose={closeSnackBar} severity="success"/>
            }

        </form>
    )
}