import { IconButton, Typography } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
/**
 * 
 * @returns sx={{
                display: { xs: 'none', md: 'flex' },
            }}
 */
export default function SignInButton(){
    const {user, signout} = useAuth();
    console.log({user, uid:user?.uid})
    const navigate = useNavigate()
	if (user){
        return (
        <IconButton color="inherit" onClick={()=>signout()}
        >
            <LogoutIcon />
        </IconButton>
        )
    }
    return (
        <IconButton color="inherit" onClick={()=>navigate("/signIn")}
        >
            <LoginIcon/>
        </IconButton>
    )
}