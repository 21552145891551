import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';

import Link from '@mui/material/Link';
import HelpIcon from '@mui/icons-material/HelpOutline';
//import { MainListItems, secondaryListItems } from './menuItems';
//import { useSelector } from 'react-redux';
//import { selectCurrentToken } from "../../modules/reduxStore/authSlice";
//import { HomePage } from '../home';
//import {Logo} from '../../templates/logo';
import {useNavigate} from "react-router-dom"
import DisclaimerModal from './disclaimerModal';
import SignInButton from './buttons/SignInButton';

function Copyright(props: any) {
    return (
        <div style={{display:"flex", margin:"0 auto", gap:"12px", width:"100%", justifyContent:"center", alignItems:"center"}}>
            <DisclaimerModal/>
            
            </div>
        );
    }
  
    const drawerWidth: number = 250;
    
    interface AppBarProps extends MuiAppBarProps {
        open?: boolean;
    }
  
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })<AppBarProps>(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        }),
    }));
  

  
const mdTheme = createTheme();

const DrawerTitle = ({link}:{link:string}) => {
    return (
        <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ alignItems:'center', display:"flex", marginRight:"auto"}}>
            <Typography
                variant="h6"
                noWrap
                component="a"
                href={link}
                sx={{
                mr: 0,
                display:'flex',
                fontFamily: 'monospace',
                fontWeight: 900,
                letterSpacing: '.25rem',
                color: 'inherit',
                textDecoration: 'none',
                }}
            > IB</Typography>
            <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href={link}
                    sx={{
                    mr: 2,
                    display:'flex',
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'inherit',
                    textDecoration: 'none',
                }}> lite </Typography>
        </Typography>
    )
}

const HelpContactButton = ({link}:{link:string}) => {
    const navigate = useNavigate();
    return (
        <IconButton color="inherit" onClick={()=>navigate(link)}>
            <HelpIcon/>
        </IconButton>
    )
}


const Drawer = ({children, helpLink, titleLink}:{children:React.ReactNode, helpLink:string, titleLink:string}) => {
    
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={false} >
                <Toolbar
                    sx={{backgroundColor:"rgb(41,40,42)"}}
                >
                    <DrawerTitle link={titleLink}/>
                    <SignInButton/>
                    <HelpContactButton link={helpLink} />
                </Toolbar>
            </AppBar>
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="lg" style={{margin: "36px auto", width:"100%"}} >
                    <div style={{margin:"0 auto", maxWidth:"800px"}}>
                    {children}
                    </div>
                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
        </Box>
      </ThemeProvider>
    )
}


export const UnAuthedDrawer = ({children}:{children:React.ReactNode})=>{
    return (
        <Drawer helpLink='/help' titleLink='/'>
            {children}
        </Drawer>
    )
}


export const AuthedDrawer = ({children}:{children:React.ReactNode})=>{
    return (
        <Drawer helpLink='/contact' titleLink='/home'>
            {children}
        </Drawer>
    )
}

