import React from "react";
import { Card, CardHeader, CardMedia, CardContent, Typography, Divider, Checkbox,
    CardActions, Chip, Collapse, Button, Stack, Box, Stepper, StepLabel, Step, 
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon, Bookmark as BookmarkIcon,
    BookmarkBorder as BookmarkBorderIcon, ExpandLess as ExpandLessIcon} from "@mui/icons-material"
import { topics } from "../routes/practice";
import MarkSolution from "../FlashCard/MarkSolution";
import useQuestionActions, { useAuthedQuestionActions } from "../hooks/useQuestionActions";
import useAuth from "../hooks/useAuth";
import NewQuestionForm from "./forms/SubmitNewQuestionForm";



interface SolutionStepsProps  {
    steps:Array<string>,
}

const SolutionSteps = (props:SolutionStepsProps ) => {

    const [stepState, setStepState] = React.useState({
        active:-1,
        open:new Set()
    });
    const {active} = stepState;
    const {steps} = props;

    const handleClick = (index:number) => {
        if (stepState.open.has(index)){
            const s = stepState.open;
            s.delete(index);
            setStepState({...stepState, active: -1, open:s})
        } else {
            const s = stepState.open;
            s.add(index);
            setStepState({...stepState, active: index, open:s})
        }
    }

  return (
    <Box sx={{ maxWidth: 600 }}>
        <Stepper nonLinear activeStep={active} orientation="vertical">
            {steps.map((step, index) => (
            <Step key={index}>
                <div style={{display:"flex"}}>
                <StepLabel>
                    
                </StepLabel>
                <Button
                        onClick={()=>handleClick(index)}
                        sx={{mr: 1 }}
                        >
                        {step[0]}
                </Button>
                </div>
                {stepState.open.has(index) ?
                <Box sx={{ mb: 2 }}>
                    <Typography>{step[1]}</Typography>
                </Box> : null}
            </Step>
            ))}
        </Stepper>
    </Box>
    )
}

interface QuestionProps {
    id:number, 
    prompt:string, 
    topic:string, 
    solution?:string, 
    solutionSteps?:Array<string>, 
    solutionItems?:Array<string>,
    difficulty?:string
    isMultiStep:boolean,
    saved?: boolean
}

interface FlashCardProps {
    question: QuestionProps,
}

export const FlashCard = (props: FlashCardProps) => {
    
    const {question} = props;
    const {id, prompt, topic, solution, solutionSteps, difficulty,} = question;
    const [expanded, setExpanded] = React.useState(false);
    const parsedSolution = solution && solution.length > 0 ? solution.split('\n') : [solution]
    const img = null;
    const {user} = useAuth();
    const questionActions = useQuestionActions();
    const authedQuestionActions = useAuthedQuestionActions();
    const {markSaved, saved} = user? authedQuestionActions :questionActions;

    React.useEffect(()=>{
        setExpanded(false);
    },[question])
       
    return (
        <Card>
            <CardHeader
            action={
                <Checkbox
                    checked={saved[id] ? true: false}
                    icon={<BookmarkBorderIcon />}
                    checkedIcon={<BookmarkIcon />}
                    onChange={()=>markSaved(id)}
                />
            }
            avatar={
                <Stack direction="row" spacing={2}> {
                    [topic].map((t, index)=>(
                        <Chip label={topics.filter((top)=>top.value===t)[0].label} variant="outlined" color="primary" size="small" key={index}/>
                    ))}
                    {difficulty && <Chip label={difficulty} variant="outlined" color={difficulty==="Easy" ?"success" :difficulty==="Hard"?"error":"warning"} size="small"/>}
                </Stack>
            }/>
        
        {img ? <CardMedia
                component="img"
                height="194"
                image={img}
                alt=""
            /> : null}
        <CardContent>
            
            <Typography variant="h6" color="text.secondary" sx={{pb:"8px"}}>
             Question
            </Typography>
            <Typography variant="body1" color="text.primary"> {prompt} </Typography>
            <NewQuestionForm id={id}/>     
        </CardContent>
        <CardActions >
            <Button size="small" onClick={()=>setExpanded(!expanded)} sx={{ml:"auto", mr:"16px", pr:"12px"}}>
                {expanded ? <ExpandLessIcon />  : <ExpandMoreIcon /> }
                {expanded ? "Hide Solution" : "View Solution"}
            </Button>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit id="collapsable-solution">
            <CardContent>
            <Divider sx={{mb:"8px"}}/>
            <Typography variant="h6" color="text.secondary" sx={{pb:"12px"}}>
             Solution
            </Typography>
          
            {parsedSolution.map((s, index)=>(
                <Typography paragraph key={index}>
                    {s}                        
                </Typography>
            ))}
          
            
           
            {Array.isArray(solutionSteps) 
            && solutionSteps.length > 0  ? <SolutionSteps steps={solutionSteps} /> : <></>}
            <MarkSolution id={id}/>
            </CardContent>
        </Collapse>
    </Card>
    )
}