
const data = [
    {
        "id":1, 
        "prompt":"What are the three financial statements and why they are important?", 
        "isMultiStep":true, "difficulty":"Easy",
        "solutionSteps":[
            ["Balance Sheets", "Show's a company’s assets and liabilities, including shareholder equity, debt, and accounts payable"],
            ["Income Statement", "This statement show a company’s cash flow from operating, financing, and investing activities"],
            ["Cash Flow", "Show's a company’s cash flow from operating, financing, and investing activities"]],
        "topic":"accounting"
    },
    {
        "id":3, 
        "prompt":"What is the formula for enterprise value?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`EV = MC + Total Debt – C
        \n- Market capitalization (MC) is the current stock price times the number of outstanding shares.
        \n- Total debt is the cumulative amount of short and long-term debt.
        \n- Cash (C) is liquid assets.`,
        "topic":"ene"
    },
    {
        "id":4, 
        "prompt":"What is the formula for WACC?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`WACC = (E/V x Re) + (D/V x Rd x (1-T))

        \n- Equity (E) is the market value of the company’s outstanding shares, so E/V is the percentage of the company’s value that is equity.
        \n- Debt (D) is the market value of the company’s debt, so D/V is the percentage of the company’s value that is debt.  
        \n- Value (V) is the value of the company’s capital, or E+D.
        \n- Re is the cost of equity
        \n- Rd is the cost of debt
        \n- Tax (T) is the corporate tax rate. `,
        "topic":"valuation"
    },
    {
        "id":5, 
        "prompt":"What does WACC calculate?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`Determines the return on investment in a company, and it is comprised of the sum of a company’s proportional debt and equity multiplied by the cost of debt and cost of equity`,
        "topic":"valuation"
    },
    {
        "id":6, 
        "prompt":"What are the major line items on an Income Statement?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`
            \n- Revenue 
            \n- Cost of Goods Sold 
            \n- SG&A (Selling, General & Administrative Expenses)
            \n- Operating Income 
            \n- Pretax Income 
            \n- Net Income`,
        "topic":"accounting"
    },
    {
        "id":7, 
        "prompt":"What are the major line items on a Balance Sheet?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`
            \n- Cash 
            \n- Accounts Receivable 
            \n- Inventory
            \n- Plants
            \n- Property & Equipment (PP&E) 
            \n- Accounts Payable
            \n- Accrued Expenses
            \n- Debt
            \n- Shareholders’ Equity`,
        "topic":"accounting"
    },
    {
        "id":8, 
        "prompt":"What are the major line items on a Cash Flow Statement?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`
            \n- Net Income 
            \n- Depreciation & Amortization 
            \n- Stock-Based Compensation 
            \n- Changes in Operating Assets & Liabilities 
            \n- Cash Flow From Operations
            \n- Capital Expenditures
            \n- Cash Flow From Investing
            \n- Sale/Purchase of Securities
            \n- Dividends Issued 
            \n- Cash Flow From Financing`,
        "topic":"accounting"
    },
    {
        "id":9, 
        "prompt":"How do the 3 financial statements link togeher?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`To tie the statements together, Net Income from the Income Statement flows into
        Shareholders’ Equity on the Balance Sheet, and into the top line of the Cash Flow
        Statement.
        \nChanges to Balance Sheet items appear as working capital changes on the Cash Flow
        Statement, and investing and financing activities affect Balance Sheet items such as
        PP&E, Debt and Shareholders’ Equity. The Cash and Shareholders’ Equity items on the
        Balance Sheet act as “plugs,” with Cash flowing in from the final line on the Cash Flow
        Statement.`,
        "topic":"accounting"
    },
    {
        "id":10, 
        "prompt":"If I only had 1 statement and I wanted to review the overall health of a company – which statement would I use and why?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`You would use the Cash Flow Statement because it gives a true picture of how much
        cash the company is actually generating, independent of all the non-cash expenses you
        might have. And that’s the #1 thing you care about when analyzing the overall financial
        health of any business – its cash flow`,
        "topic":"accounting"
    },
    {
        "id":11, 
        "prompt":"If I could only look at 2 statements to assess a company’s prospects – which 2 would I use and why?", 
        "isMultiStep":false, "difficulty":"Easy",
        "solution":`You would pick the Income Statement and Balance Sheet, because you can create the
        Cash Flow Statement from both of those (assuming, of course that you have “before”
        and “after” versions of the Balance Sheet that correspond to the same period the Income
        Statement is tracking).`,
        "topic":"accounting"
    },
    {
        "id":12, 
        "prompt":"Walk me through how Depreciation going up by $10 would affect the three financial statements (assuming 40% tax rate).", 
        "isMultiStep":true, "difficulty":"Easy", 
        "solutionSteps":[
            ["Income Statement", "Operating Income would decline by $10 and assuming a 40% tax rate, Net Income would go down by $6."],
            ["Cash Flow Statement", `The Net Income at the top goes down by $6, but the $10 Depreciation is a non-cash expense that gets added back, so overall Cash Flow from
            Operations goes up by $4. There are no changes elsewhere, so the overall Net Change in
            Cash goes up by $4.`],
            ["Balance Sheet", `Plants, Property & Equipment goes down by $10 on the Assets side because of the Depreciation, and Cash is up by $4 from the changes on the Cash Flow Statement.`],
            ["Overall Result", `Assets is down by $6. Since Net Income fell by $6 as well, Shareholders’ Equity
            on the Liabilities & Shareholders’ Equity side is down by $6 and both sides of the
            Balance Sheet balancet.`],
        ],
        "topic":"accounting"
    },
    {
        "id":13,
        "prompt":"If Depreciation is a non-cash expense, why does it affect the cash balance?",
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"accounting",
        "solution":"Although Depreciation is a non-cash expense, it is tax-deductible. Since taxes are a cash expense, Depreciation affects cash by reducing the amount of taxes you pay.",
    },{
        "id":14,
        "prompt":"Where does Depreciation usually show up on the Income Statement??",
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"accounting",
        "solution":"It could be in a separate line item, or it could be embedded in Cost of Goods Sold or Operating Expenses – every company does it differently. Note that the end result for accounting questions is the same: Depreciation always reduces Pre-Tax Income.",
    },{
        "id":15,
        "prompt":"What happens when Accrued Compensation goes up by $10 (assume accrued compensation is being recognized as an expense and 40% tax rate)?",
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"accounting",
        "solutionSteps":[
            ["Income Statement", "Operating Expenses on the Income Statement go up by $10, Pre-Tax Income falls by $10, and Net Income falls by $6."],
            ["Cash Flow Statement", `Net Income is down by $6, and Accrued Compensation
            will increase Cash Flow by $10, so overall Cash Flow from Operations is up by $4 and the
            Net Change in Cash at the bottom is up by $4`],
            ["Balance Sheet", `Cash is up by $4 as a result, so Assets are up by $4. On the
            Liabilities & Equity side, Accrued Compensation is a liability so Liabilities are up by $10
            and Retained Earnings are down by $6 due to the Net Income, so both sides balance.`],
        ],    
    },
    {
        "id":16,
        "prompt":"What happens when Inventory goes up by $10, assuming you pay for it with cash?",
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"accounting",
        "solutionSteps":[
            ["Income Statement", "No changes."],
            ["Cash Flow Statement", `Inventory is an asset so that decreases your Cash Flow from
            Operations – it goes down by $10, as does the Net Change in Cash at the bottom.`],
            ["Balance Sheet", `On the Balance Sheet under Assets, Inventory is up by $10 but Cash is down by $10, so
            the changes cancel out and Assets still equals Liabilities & Shareholders’ Equity.`],
        ],    
    },
    {
        "id":17,
        "prompt":"Why is the Income Statement not affected by changes in Inventory?",
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"accounting",
        "solution":`In the case of Inventory, the expense is only recorded when the goods associated with it
        are sold – so if it’s just sitting in a warehouse, it does not count as a Cost of Good Sold or
        Operating Expense until the company manufactures it into a product and sells it.`,    
    },
    {
        "id":18,
        "prompt":"Let’s say Apple is buying $100 worth of new iPad factories with debt. How are all 3 statements affected at the start of “Year 1,” before anything else happens?",
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"accounting",
        "solutionSteps":[
            ["Income Statement", `At the start of “Year 1,” before anything else has happened, there would be no changes
            on Apple’s Income Statement (yet).`],
            ["Cash Flow Statement", `The additional investment in factories would show up
            under Cash Flow from Investing as a net reduction in Cash Flow (so Cash Flow is down
            by $100 so far). And the additional $100 worth of debt raised would show up as an
            addition to Cash Flow, canceling out the investment activity. So the cash number stays
            the same.`],
            ["Balance Sheet", `There is now an additional $100 worth of factories in the Plants,
            Property & Equipment line, so PP&E is up by $100 and Assets is therefore up by $100.
            On the other side, debt is up by $100 as well and so both sides balance.`],
        ],   
    },
    {
        "id":19,
        "prompt":`Let’s say Apple is buying $100 worth of new iPad factories with debt. Now let’s go out 1 year, to the start of Year 2. Assume the debt is high-yield so no
        principal is paid off, and assume an interest rate of 10%. Also assume the factories
        depreciate at a rate of 10% per year. Assume a tax rate of 40%. What happens?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"accounting",
        "solutionSteps":[
            ["Income Statement", `Operating Income would decrease by $10 due to the 10% depreciation charge each year,
            and the $10 in additional Interest Expense would decrease the Pre-Tax Income by $20
            altogether ($10 from the depreciation and $10 from Interest Expense).
            Assuming a tax rate of 40%, Net Income would fall by $12.`],
            ["Cash Flow Statement", `Net Income at the top is down by $12. Depreciation is a
            non-cash expense, so you add it back and the end result is that Cash Flow from
            Operations is down by $2.
            That’s the only change on the Cash Flow Statement, so overall Cash is down by $2.`],
            ["Balance Sheet", `On the Balance Sheet, under Assets, Cash is down by $2 and PP&E is down by $10 due
            to the depreciation, so overall Assets are down by $12.
            On the other side, since Net Income was down by $12, Shareholders’ Equity is also
            down by $12 and both sides balance`],
            ["Overall Result", `After a year has passed, Apple must pay interest expense and must record the
            depreciation. Remember, the debt number under Liabilities does not change since we’ve assumed
            none of the debt is actually paid back.`],
        ],   
    },
    {
        "id":20,
        "prompt":`How is GAAP accounting different from tax accounting?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"accounting",
        "solutionSteps":[
            ["Part I", `GAAP is accrual-based but tax is cash-based.`],
            ["Part II", `GAAP uses straight-line depreciation or a few other methods whereas tax
            accounting is different (accelerated depreciation).`],
            ["Part III", `GAAP is more complex and more accurately tracks assets/liabilities whereas tax
            accounting is only concerned with revenue/expenses in the current period and
            what income tax you owe.`],
        ],   
    },
    {
        "id":21,
        "prompt":`Why do we look at both Enterprise Value and Equity Value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Enterprise Value represents the value of the company that is attributable to all investors;
        Equity Value only represents the portion available to shareholders (equity investors).
        You look at both because Equity Value is the number the public-at-large sees, while
        Enterprise Value represents its true value.`
    },
    {
        "id":22,
        "prompt":`When looking at an acquisition of a company, do you pay more attention to
        Enterprise or Equity Value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Enterprise Value represents the value of the company that is attributable to all investors;
        Equity Value only represents the portion available to shareholders (equity investors).
        You look at both because Equity Value is the number the public-at-large sees, while
        Enterprise Value represents its true value.`
    },
    {
        "id":23,
        "prompt":`What’s the formula for Enterprise Value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`EV = Equity Value + Debt + Preferred Stock + Noncontrolling Interest – Cash
        \nThis formula does not tell the whole story and can get more complex. Most of the time you can get away with stating this formula in an interview,
        though.
        \nNOTE: “Noncontrolling Interest” was formerly known as Minority Interest and some bankers
        still call it that.`
    },
    {
        "id":24,
        "prompt":`Why do you need to add the Noncontrolling Interest to Enterprise Value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Whenever a company owns over 50% of another company, it is required to report the
            financial performance of the other company as part of its own performance.
            So even though it doesn’t own 100%, it reports 100% of the majority-owned subsidiary’s
            financial performance.\n
            In keeping with the “apples-to-apples” theme, you must add the Noncontrolling Interest
            to get to Enterprise Value so that your numerator and denominator both reflect 100% of
            the majority-owned subsidiary.`
    },
    {
        "id":25,
        "prompt":`How do you calculate fully diluted shares?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Take the basic share count and add in the dilutive effect of stock options and any other
        dilutive securities, such as warrants, convertible debt or convertible preferred stock.
        To calculate the dilutive effect of options, you use the Treasury Stock Method (detail on
        this below).`
    },
    {
        "id":26,
        "prompt":`Let’s say a company has 100 shares outstanding, at a share price of $10 each. It also
        has 10 options outstanding at an exercise price of $5 each – what is its fully diluted
        equity value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Its basic equity value is $1,000 (100 * $10 = $1,000). To calculate the dilutive effect of the
        options, first you note that the options are all “in-the-money” – their exercise price is less
        than the current share price.
        \nWhen these options are exercised, there will be 10 new shares created – so the share
        count is now 110 rather than 100.
        \nHowever, that doesn’t tell the whole story. In order to exercise the options, we had to
        “pay” the company $5 for each option (the exercise price).
        \nAs a result, it now has $50 in additional cash, which it now uses to buy back 5 of the new
        shares we created.
        \nSo the fully diluted share count is 105, and the fully diluted equity value is $1,050`
    },
    {
        "id":27,
        "prompt":`Let’s say a company has 100 shares outstanding, at a share price of $10 each. It also
        has 10 options outstanding at an exercise price of $15 each – what is its fully diluted
        equity value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`$1,000. In this case the options’ exercise price is above the current share price, so they
        have no dilutive effect.`
    },
    {
        "id":28,
        "prompt":`Why do you subtract cash in the formula for Enterprise Value? Is that always
        accurate?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Cash is subtracted because it’s considered a non-operating asset
        and because Equity Value implicitly accounts for it.\nThe way to think about it: In an acquisition, the buyer would “get” the cash of the seller,
        so it effectively pays less for the company based on how large its cash balance is.
        Remember, Enterprise Value tells us how much you’d really have to “pay” to acquire
        another company.
        \nIt’s not always accurate because technically you should be subtracting only excess cash –
        the amount of cash a company has above the minimum cash it requires to operate.`
    },
    {
        "id":29,
        "prompt":`Is it always accurate to add Debt to Equity Value when calculating Enterprise Value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`In most cases, yes, because the terms of a debt agreement usually say that debt must be
        refinanced in an acquisition. And in most cases a buyer will pay off a seller’s debt, so it
        is accurate to say that any debt “adds” to the purchase price.
        \nHowever, there could always be exceptions where the buyer does not pay off the debt.
        These are rare and I’ve personally never seen it, but once again “never say never”
        applies.`
    },
    {
        "id":30,
        "prompt":` Could a company have a negative Enterprise Value? What would that mean?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Yes. It means that the company has an extremely large cash balance, or an extremely
        low market capitalization (or both). You see it with:
        \n- Companies on the brink of bankruptcy.
        \n- Financial institutions, such as banks, that have large cash balances – but
        Enterprise Value is not even used for commercial banks in the first place so this
        doesn’t matter much.`
    },
    {
        "id":31,
        "prompt":`Could a company have a negative Equity Value? What would that mean?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`No. This is not possible because you cannot have a negative share count and you cannot
        have a negative share price.`
    },
    {
        "id":32,
        "prompt":`Why do we add Preferred Stock to get to Enterprise Value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Preferred Stock pays out a fixed dividend, and preferred stock holders also have a
        higher claim to a company’s assets than equity investors do. As a result, it is seen as
        more similar to debt than common stock.`
    },
    {
        "id":33,
        "prompt":`How do you account for convertible bonds in the Enterprise Value formula?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`If the convertible bonds are in-the-money, meaning that the conversion price of the
        bonds is below the current share price, then you count them as additional dilution to the
        Equity Value; if they’re out-of-the-money then you count the face value of the
        convertibles as part of the company’s Debt.`
    },
    {
        "id":34,
        "prompt":`A company has 1 million shares outstanding at a value of $100 per share. It also
        has $10 million of convertible bonds, with par value of $1,000 and a conversion price
        of $50. How do I calculate diluted shares outstanding?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`This gets confusing because of the different units involved. 
        \nFirst, note that these convertible bonds are in-the-money because the company’s share price is $100, but the
        conversion price is $50. So we count them as additional shares rather than debt.
        Next, we need to divide the value of the convertible bonds – $10 million – by the par
        value – $1,000 – to figure out how many individual bonds we get:
        \n$10 million / $1,000 = 10,000 convertible bonds.
        \nNext, we need to figure out how many shares this number represents. The number of
        shares per bond is the par value divided by the conversion price:
        \n$1,000 / $50 = 20 shares per bond.
        \nSo we have 200,000 new shares (20 * 10,000) created by the convertibles, giving us 1.2
        million diluted shares outstanding.
        \nWe do not use the Treasury Stock Method with convertibles because the company is
        not “receiving” any cash from us`
    },
    {
        "id":35,
        "prompt":`What’s the difference between Equity Value and Shareholders’ Equity?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"ene",
        "solution":`Equity Value is the market value and Shareholders’ Equity is the book value. Equity
        Value can never be negative because shares outstanding and share prices can never be
        negative, whereas Shareholders’ Equity could be any value. For healthy companies,
        Equity Value usually far exceeds Shareholders’ Equity.`
    },{
        "id":36,
        "prompt":`What are the 3 major valuation methodologies?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`\n1. Comparable Companies \n2. Precedent Transactions \n3.Discounted Cash Flow Analysis.`
    },{
        "id":37,
        "prompt":`Rank the 3 valuation methodologies from highest to lowest expected value.`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`Trick question – there is no ranking that always holds. In general, Precedent
        Transactions will be higher than Comparable Companies due to the Control Premium
        built into acquisitions.
        \nBeyond that, a DCF could go either way and it’s best to say that it’s more variable than
        other methodologies. Often it produces the highest value, but it can produce the lowest
        value as well depending on your assumptions.`
    },
    {
        "id":38,
        "prompt":`When would you not use a DCF in a Valuation?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`You do not use a DCF if the company has unstable or unpredictable cash flows (tech or
            bio-tech startup) or when debt and working capital serve a fundamentally different role.
            For example, banks and financial institutions do not re-invest debt and working capital
            is a huge part of their Balance Sheets – so you wouldn’t use a DCF for such companies.`
    },
    {
        "id":39,
        "prompt":`Describe six different valuation methodologies?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"valuation",
        "solutionSteps":[
            ["Liquid Valuation", `Valuing a company’s assets, assuming they are sold off and
            then subtracting liabilities to determine how much capital, if any, equity investors
            receive`],
            ["Replacement Value", `Valuing a company based on the cost of replacing its assets`],
            ["LBO Analysis", `Determining how much a PE firm could pay for a company to hit a
            “target” IRR, usually in the 20-25% range`],
            ["Sum of the Parts", `Valuing each division of a company separately and adding them
            together at the end`],
            ["M&A Premiums Analysis", `Analyzing M&A deals and figuring out the premium
            that each buyer paid, and using this to establish what your company is worth
            `],
            ["Future Share Price Analysis", `Projecting a company’s share price based on the P / E
            multiples of the public company comparables, then discounting it back to its present
            value
            `],
        ],   
    },{
        "id":40,
        "prompt":`When would you use a Liquidation Valuation?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`This is most common in bankruptcy scenarios and is used to see whether equity
        shareholders will receive any capital after the company’s debts have been paid off. It is
        often used to advise struggling businesses on whether it’s better to sell off assets
        separately or to try and sell the entire company.`
    },{
        "id":41,
        "prompt":`When would you use Sum of the Parts?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`This is most often used when a company has completely different, unrelated divisions –
        a conglomerate like General Electric, for example.
        If you have a plastics division, a TV and entertainment division, an energy division, a
        consumer financing division and a technology division, you should not use the same set
        of Comparable Companies and Precedent Transactions for the entire company.
        Instead, you should use different sets for each division, value each one separately, and
        then add them together to get the Combined Value.`
    },
    {
        "id":42,
        "prompt":`When do you use an LBO Analysis as part of your Valuation?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`Obviously you use this whenever you’re looking at a Leveraged Buyout – but it is also
        used to establish how much a private equity firm could pay, which is usually lower than
        what companies will pay.
        It is often used to set a “floor” on a possible Valuation for the company you’re looking at.`
    },
    {
        "id":43,
        "prompt":`What are the most common multiples used in Valuation?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`The most common multiples are EV/Revenue, EV/EBITDA, EV/EBIT, P/E (Share Price /
        Earnings per Share), and P/BV (Share Price / Book Value per Share).`
    },
    {
        "id":44,
        "prompt":`What are the most common multiples used in Valuation?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"valuation",
        "solutionSteps":[
            ["Technology", `EV / Unique Visitors, EV / Pageviews. \nyou’re looking at traffic and energy
            reserves as value drivers rather than revenue or profit.`],
            ["Retail/Airlines", `EV / EBITDAR (Earnings Before Interest, Taxes, Depreciation,
                Amortization & Rental Expense). \nyou add back Rent because some companies own their own
                buildings and capitalize the expense whereas others rent and therefore have a rental
                expense`],
            ["Energy", `EV / EBITDAX (Earnings Before Interest, Taxes, Depreciation, Amortization &
                Exploration Expense), EV / Daily Production, EV / Proved Reserve Quantities. \nyou’re looking at traffic and energy
                reserves as value drivers rather than revenue or profit.\n All value is derived from companies’ reserves of oil & gas, which explains
                the last 2 multiples; EBITDAX exists because some companies capitalize (a portion of)
                their exploration expenses and some expense them. You add back the exploration
                expense to normalize the numbers`],
            ["Real Estate Investment Trusts (REITs)", `Price / FFO per Share, Price / AFFO per Share
            (Funds From Operations, Adjusted Funds From Operations). \nFunds From Operations is a common metric that adds back Depreciation and
            subtracts gains on the sale of property. Depreciation is a non-cash yet extremely large
            expense in real estate, and gains on sales of properties are assumed to be non-recurring,
            so FFO is viewed as a “normalized” picture of the cash flow the REIT is generating.`]
        ],
    },{
        "id":45,
        "prompt":`When you’re looking at an industry-specific multiple like EV / Scientists or EV /
        Subscribers, why do you use Enterprise Value rather than Equity Value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`You use Enterprise Value because those scientists or subscribers are “available” to all the
        investors (both debt and equity) in a company. The same logic doesn’t apply to
        everything, though – you need to think through the multiple and see which investors
        the particular metric is “available” to.`
    },
    {
        "id":46,
        "prompt":`Would an LBO or DCF give a higher valuation?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`Technically it could go either way, but in most cases the LBO will give you a lower
        valuation.
        \nHere’s the easiest way to think about it: with an LBO, you do not get any value from the
        cash flows of a company in between Year 1 and the final year – you’re only valuing it
        based on its terminal value.
        \nWith a DCF, by contrast, you’re taking into account both the company’s cash flows in
        between and its terminal value, so values tend to be higher.
        \nNote: Unlike a DCF, an LBO model by itself does not give a specific valuation. Instead,
        you set a desired IRR and determine how much you could pay for the company (the
        valuation) based on that.`
    },
    {
        "id":47,
        "prompt":`How would you present Valuation methodologies to a company or its
        investors?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`Usually you use a “football field” chart where you show the valuation range implied by
        each methodology. You always show a range rather than one specific number.
        \nAs an example, see page 10 of this document (a Valuation done by Credit Suisse for the
        Leveraged Buyout of Sungard Data Systems in 2005):\n\n
        http://edgar.sec.gov/Archives/edgar/data/789388/000119312505074184/dex99c2.htm`
    },
    {
        "id":48,
        "prompt":`How would you value an apple tree?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`The same way you would value a company: by looking at what comparable apple trees
        are worth (relative valuation) and the value of the apple tree’s cash flows (intrinsic
        valuation).
        \nYes, you could do a DCF for anything – even an apple tree.`
    },
    {
        "id":49,
        "prompt":`Why can’t you use Equity Value / EBITDA as a multiple rather than Enterprise
        Value / EBITDA?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`EBITDA is available to all investors in the company – rather than just equity holders.
        Similarly, Enterprise Value is also available to all shareholders so it makes sense to pair
        them together.
        \nEquity Value / EBITDA, however, is comparing apples to oranges because Equity Value
        does not reflect the company’s entire capital structure – only the part available to equity
        investors.`
    },
    
    {
        "id":50,
        "prompt":`When would a Liquidation Valuation produce the highest value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`This is highly unusual, but it could happen if a company had substantial hard assets but
        the market was severely undervaluing it for a specific reason (such as an earnings miss
        or cyclicality).
        As a result, the company’s Comparable Companies and Precedent Transactions would
        likely produce lower values as well – and if its assets were valued highly enough,
        Liquidation Valuation might give a higher value than other methodologies`
    },{
        "id":51,
        "prompt":`Let’s go back to 2004 and look at Facebook back when it had no profit and no
        revenue. How would you value it?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`You would use Comparable Companies and Precedent Transactions and look at more
        “creative” multiples such as EV/Unique Visitors and EV/Pageviews rather than
        EV/Revenue or EV/EBITDA.
        \nYou would not use a “far in the future DCF” because you can’t reasonably predict cash
        flows for a company that is not even making money yet.
        This is a very common wrong answer given by interviewees. When you can’t predict
        cash flow, use other metrics – don’t try to predict cash flow anyway!`
    },
    {
        "id":52,
        "prompt":`What would you use in conjunction with Free Cash Flow multiples – Equity Value
        or Enterprise Value?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`Trick question. 
        \nFor Unlevered Free Cash Flow, you would use Enterprise Value, but for
        Levered Free Cash Flow you would use Equity Value.
        \nRemember, Unlevered Free Cash Flow excludes Interest and thus represents money
        available to all investors, whereas Levered FCF already includes the effects of the Interest expense (and mandatory debt repayments) and the money is therefore only
        available to equity investors.
        \nDebt investors have already “been paid” with the interest payments and principal re
        payments they received.`
    },
    {
        "id":53,
        "prompt":`You never use Equity Value / EBITDA, but are there any cases where you might
        use Equity Value / Revenue?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`It’s very rare to see this, but sometimes large financial institutions with big cash balances
        have negative Enterprise Values – so you might use Equity Value / Revenue instead.
        \nYou might see Equity Value / Revenue if you’ve listed a set of financial institutions and
        non-financial institutions on a slide, you’re showing Revenue multiples for the nonfinancial institutions, and you want to show something similar for the financial
        institutions.
        \nNote, however, that in most cases you would be using other multiples such as P/E and
        P/BV with banks anyway.`
    },
    {
        "id":54,
        "prompt":`What are the three main ways to select Comparable Companies / Precedent Transactions?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"valuation",
     
        "solutionSteps":[
            ["Part I", `Industry Classification`],
            ["Part II", `Financial Criteria (Revenue, EBITDA, etc.)`],
            ["Part III", `Geography`],
        ],
    },
    {
        "id":55,
        "prompt":`How do you select Comparable Companies / Precedent Transactions for different industries)?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"valuation",
        "solution":`For Precedent Transactions, you often limit the set based on date and only look at
        transactions within the past 1-2 years.\nThe most important factor is industry – that is always used to screen for
        companies/transactions, and the rest may or may not be used depending on how specific
        you want to be.`,
        "solutionSteps":[
            ["Oil and Gas", `Comparable Company Screen`],
            ["Digital Media", `Comparable Company Screen`],
            ["Airline", `Precedent Transaction Screen`],
            ["Retail", `Precedent Transaction Screen`],
        ],
    },{
        "id":56,
        "prompt":`What is a Discounted Cash Flow Statement?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"dcf",
        "solution":`A DCF values a company based on the Present Value of its Cash Flows and the Present
        Value of its Terminal Value.
        \nFirst, you project out a company’s financials using assumptions for revenue growth,
        expenses and Working Capital; then you get down to Free Cash Flow for each year,
        which you then sum up and discount to a Net Present Value, based on your discount
        rate – usually the Weighted Average Cost of Capital.
        Once you have the present value of the Cash Flows, you determine the company’s
        Terminal Value, using either the Multiples Method or the Gordon Growth Method, and
        then also discount that back to its Net Present Value using WACC.
        \nFinally, you add the two together to determine the company’s Enterprise Value.`,
        
    },
    {
        "id":57,
        "prompt":`How you get from Revenue to Free Cash Flow in the projections?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"dcf",
        "solution":`Subtract COGS and Operating Expenses to get to Operating Income (EBIT). Then,
        multiply by (1 – Tax Rate), add back Depreciation and other non-cash charges, and
        subtract Capital Expenditures and the change in Working Capital.\nNote: This gets you to Unlevered Free Cash Flow since you went off EBIT rather than
        EBT. You should confirm that this is what the interviewer is asking for.`,
        
    },{
        "id":58,
        "prompt":`Why do you use 5 or 10 years for DCF projections?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"dcf",
        "solution":`That’s usually about as far as you can reasonably predict into the future. Less than 5
        years would be too short to be useful, and over 10 years is too difficult to predict for
        most companies.`,
    },{
        "id":59,
        "prompt":`What’s an alternate way to calculate Free Cash Flow aside from taking Net Income,
        adding back Depreciation, and subtracting Changes in Operating Assets / Liabilities
        and CapEx?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"dcf",
        "solution":`Take Cash Flow From Operations and subtract CapEx and mandatory debt repayments
        – that gets you to Levered Cash Flow. To get to Unlevered Cash Flow, you then need to
        add back the tax-adjusted Interest Expense and subtract the tax-adjusted Interest Income.`,
    },{
        "id":60,
        "prompt":`What do you usually use for the discount rate?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"dcf",
        "solution":`Normally you use WACC (Weighted Average Cost of Capital), though you might also
        use Cost of Equity depending on how you’ve set up the DCF.`,
    },{
        "id":61,
        "prompt":`How do you calculate the Cost of Equity?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"dcf",
        "solution":`Cost of Equity = Risk-Free Rate + Beta * Equity Risk Premium
        \nThe risk-free rate represents how much a 10-year or 20-year US Treasury should yield;
        Beta is calculated based on the “riskiness” of Comparable Companies and the Equity
        Risk Premium is the % by which stocks are expected to out-perform “risk-less” assets.
        \nNormally you pull the Equity Risk Premium from a publication called Ibbotson’s.
        \nNote: This formula does not tell the whole story. Depending on the bank and how
        precise you want to be, you could also add in a “size premium” and “industry premium” to account for how much a company is expected to out-perform its peers is
        according to its market cap or industry.
        \nSmall company stocks are expected to out-perform large company stocks and certain
        industries are expected to out-perform others, and these premiums reflect these
        expectations.`,
    },{
        "id":62,
        "prompt":`Walk me through a basic merger model.`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"mna",
        "solution":`“A merger model is used to analyze the financial profiles of 2 companies, the purchase
        price and how the purchase is made, and determines whether the buyer’s EPS increases
        or decreases.
        \nStep 1 is making assumptions about the acquisition – the price and whether it was cash,
        stock or debt or some combination of those. Next, you determine the valuations and
        shares outstanding of the buyer and seller and project out an Income Statement for each
        one.
        \nFinally, you combine the Income Statements, adding up line items such as Revenue and
        Operating Expenses, and adjusting for Foregone Interest on Cash and Interest Paid on
        Debt in the Combined Pre-Tax Income line; you apply the buyer’s Tax Rate to get the
        Combined Net Income, and then divide by the new share count to determine the
        combined EPS.”`,
    },{
        "id":63,
        "prompt":`Walk me through a basic merger model.`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"mna",
        "solution":`A merger model is used to analyze the financial profiles of 2 companies, the purchase
        price and how the purchase is made, and determines whether the buyer’s EPS increases
        or decreases.
        \nStep 1 is making assumptions about the acquisition – the price and whether it was cash,
        stock or debt or some combination of those. Next, you determine the valuations and
        shares outstanding of the buyer and seller and project out an Income Statement for each
        one.
        \nFinally, you combine the Income Statements, adding up line items such as Revenue and
        Operating Expenses, and adjusting for Foregone Interest on Cash and Interest Paid on
        Debt in the Combined Pre-Tax Income line; you apply the buyer’s Tax Rate to get the
        Combined Net Income, and then divide by the new share count to determine the
        combined EPS.`,
    },{
        "id":64,
        "prompt":`What’s the difference between a merger and an acquisition?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"mna",
        "solution":`There’s always a buyer and a seller in any M&A deal – the difference between “merger”
        and “acquisition” is more semantic than anything. In a merger the companies are close
        to the same size, whereas in an acquisition the buyer is significantly larger.`,
    },{
        "id":65,
        "prompt":`Why would a company want to acquire another company?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"mna",
        "solution":`
        \n- The buyer wants to gain market share by buying a competitor.
        \n- The buyer needs to grow more quickly and sees an acquisition as a way to do that.
        \n- The buyer believes the seller is undervalued.
        \n- The buyer wants to acquire the seller’s customers so it can up-sell and cross-sell to
        them.
        \n- The buyer thinks the seller has a critical technology, intellectual property or some
        other “secret sauce” it can use to significantly enhance its business.
        \n- The buyer believes it can achieve significant synergies and therefore make the deal
        accretive for its shareholders.`,
    },{
        "id":66,
        "prompt":`Why would an acquisition be dilutive?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"mna",
        "solution":`An acquisition is dilutive if the additional amount of Net Income the seller contributes is
        not enough to offset the buyer’s foregone interest on cash, additional interest paid on
        debt, and the effects of issuing additional shares.
        \nAcquisition effects – such as amortization of intangibles – can also make an acquisition
        dilutive`,
    },{
        "id":67,
        "prompt":`Is there a rule of thumb for calculating whether an acquisition will be accretive or
        dilutive?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"mna",
        "solution":`If the deal involves just cash and debt, you can sum up the interest expense for debt and
        the foregone interest on cash, then compare it against the seller’s Pre-Tax Income.
        \nAnd if it’s an all-stock deal you can use a shortcut to assess whether it is accretive (see
        question #5).
        \nBut if the deal involves cash, stock, and debt, there’s no quick rule-of-thumb you can use
        unless you’re lightning fast with mental math.`,
    },{
        "id":68,
        "prompt":`A company with a higher P/E acquires one with a lower P/E – is this accretive or
        dilutive?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"mna",
        "solution":`Trick question. You can’t tell unless you also know that it’s an all-stock deal. If it’s an
        all-cash or all-debt deal, the P/E multiples of the buyer and seller don’t matter because
        no stock is being issued.
        \nGenerally getting more earnings for less is good and is more likely to be accretive
        but there’s no hard-and-fast rule unless it’s an all-stock deal.`,
    },{
        "id":69,
        "prompt":`What is the rule of thumb for assessing whether an M&A deal will be accretive or
        dilutive?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"mna",
        "solution":`In an all-stock deal, if the buyer has a higher P/E than the seller, it will be accretive; if the
        buyer has a lower P/E, it will be dilutive.
        \nOn an intuitive level if you’re paying more for earnings than what the market values
        your own earnings at, you can guess that it will be dilutive; and likewise, if you’re
        paying less for earnings than what the market values your own earnings at, you can
        guess that it would be accretive.`,
    },{
        "id":70,
        "prompt":`What are the complete effects of an acquisition?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"mna",
        "solutionSteps":[
            ["Foregone Interest on Cash", "– The buyer loses the Interest it would have otherwise earned if it uses cash for the acquisition"],
            ["Additional Interest on Debt", "The buyer pays additional Interest Expense if it uses debt."],
            ["Additional Shares Outstanding", "If the buyer pays with stock, it must issue additional shares."],
            ["Combined Financial Statements", "After the acquisition, the seller’s financials are added to the buyer’s"],
            ["Creation of Goodwill & Other Intangibles","These Balance Sheet items that represent a “premium” paid to a company’s “fair value” also get created."],
        ]
    },{
        "id":71,
        "prompt":`What are the two types of synergies?`,
        "isMultiStep":true, 
        "difficulty":"Easy",
        "topic":"mna",
        "solutionSteps":[
            ["Revenue Synergies", `The combined company can cross-sell products to new
            customers or up-sell new products to existing customers. It might also be able to
            expand into new geographies as a result of the deal.`],
            ["Cost Synergies", `The combined company can consolidate buildings and
            administrative staff and can lay off redundant employees. It might also be able to
            shut down redundant stores or locations.`],
        ]
    },{
        "id":72,
        "prompt":`How would an asset write-up or write-down affect an LBO model? / Walk me
        through how you adjust the Balance Sheet in an LBO model.`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"lbo",
        "solution":`All of this is very similar to what you would see in a merger model – you calculate
        Goodwill, Other Intangibles, and the rest of the write-ups in the same way, and then the
        Balance Sheet adjustments (e.g. subtracting cash, adding in capitalized financing fees,
        writing up assets, wiping out goodwill, adjusting the deferred tax assets / liabilities,
        adding in new debt, etc.) are almost the same.
        \n- In an LBO model you assume that the existing Shareholders’ Equity is wiped out
        and replaced by the equity the private equity firm contributes to buy the
        company; you may also add in Preferred Stock, Management Rollover, or Rollover from Option Holders to this number as well depending on what you’re
        assuming for transaction financing.
        \n- In an LBO model you’ll usually be adding a lot more tranches of debt vs. what
        you would see in a merger model.
        \n- In an LBO model you’re not combining two companies’ Balance Sheets.
        `
    },{
        "id":73,
        "prompt":`Normally we care about the IRR for the equity investors in an LBO – the PE firm
        that buys the company – but how do we calculate the IRR for the debt investors?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"lbo",
        "solution":`For the debt investors, you need to calculate the interest and principal payments they
        receive from the company each year.
        \nThen you simply use the IRR function in Excel and start with the negative amount of the
        original debt for “Year 0,” assume that the interest and principal payments each year are
        your “cash flows” and then assume that the remaining debt balance in the final year is
        your “exit value.”
        \nMost of the time, returns for debt investors will be lower than returns for the equity
        investors – but if the deal goes poorly or the PE firm can’t sell the company for a good
        price, the reverse could easily be true.
        `
    },{
        "id":74,
        "prompt":`Why might a private equity firm allot some of a company’s new equity in an LBO to
        a management option pool, and how would this affect the model?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"lbo",
        "solution":`This is done for the same reason you have an Earnout in an M&A deal: the PE firm
        wants to incentivize the management team and keep everyone on-board until they exit
        the investment.
        \nThe difference is that there’s no technical limit on how much management might receive
        from such an option pool: if they hit it out of the park, maybe they’ll all become
        millionaires.
        \nIn your LBO model, you would need to calculate a per-share purchase price when the
        PE firm exits the investment, and then calculate how much of the proceeds go to the
        management team based on the Treasury Stock Method.
        An option pool by itself would reduce the PE firm’s return, but this is offset by the fact
        that the company should perform better with this incentive in place.
        `
    },{
        "id":74,
        "prompt":`Why you would you use PIK (Payment In Kind) debt rather than other types of
        debt, and how does it affect the debt schedules and the other statements?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"lbo",
        "solution":`Unlike “normal” debt, a PIK loan does not require the borrower to make cash interest
        payments – instead, the interest just accrues to the loan principal, which keeps going up
        over time. A PIK “toggle” allows the company to choose whether to pay the interest in
        cash or have it accrue to the principal (these have disappeared since the credit crunch).
        \nPIK is more risky than other forms of debt and carries with it a higher interest rate than
        traditional bank debt or high yield debt.
        \nAdding it to the debt schedules is similar to adding high-yield debt with a bullet
        maturity – except instead of assuming cash interest payments, you assume that the
        interest accrues to the principal instead.
        \nYou should then include this interest on the Income Statement, but you need to add back
        any PIK interest on the Cash Flow Statement because it’s a non-cash expense.
        `
    },{
        "id":75,
        "prompt":`What are some examples of incurrence covenants? Maintenance covenants?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"lbo",
        "solutionSteps":[
            ["Incurrence Covenants", `- Company cannot take on more than $2 billion of total debt.
            \n- Proceeds from any asset sales must be earmarked to repay debt.
            \n- Company cannot make acquisitions of over $200 million in size.
            \n- Company cannot spend more than $100 million on CapEx each year`],
            ["Maintenance Covenants", `- Total Debt / EBITDA cannot exceed 3.0 x
            \n- Senior Debt / EBITDA cannot exceed 2.0 x
            \n- (Total Cash Payable Debt + Capitalized Leases) / EBITDAR cannot exceed 4.0 x
            \n- EBITDA / Interest Expense cannot fall below 5.0 x
            \n- EBITDA / Cash Interest Expense cannot fall below 3.0 x
            \n- (EBITDA – CapEx) / Interest Expense cannot fall below 2.0 x`]
        ]
    },{
        "id":76,
        "prompt":`Just like a normal M&A deal, you can structure an LBO either as a stock purchase or
        as an asset purchase. Can you also use Section 338(h)(10) election?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"lbo",
        "solution":`In most cases, no – because one of the requirements for Section 338(h)(10) is that the
        buyer must be a C corporation. Most private equity firms are organized as LLCs or
        Limited Partnerships, and when they acquire companies in an LBO, they create an LLC
        shell company that “acquires” the company on paper.`
    },{
        "id":77,
        "prompt":`What are the 2 different "sides" of a Restructuring deal?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"restructure",
        "solution":`- Bankers can advise either the debtor (the company itself) or the creditors (anyone that has lent the company) money. It's similar to sell-side vs. buy-side M&A - 
        in one you're advising the company trying to sell or get out of the mess it's in, and in the other you're advising buyers and lenders that are trying to take what they can from the company.
        \n- "Creditors" are often multiple parties since it's anyone who loaned the company money. There are also "operational advisors" that help with the actual turnaround.`
    },{
        "id":78,
        "prompt":`What are ways a company can go bankrupt?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"restructure",
        "solution":`- A company cannot meet its debt obligations/interest payments.

        \n- Creditors can accelerate debt payments and force the company into bankruptcy. 
        
        \n- An acquisition has gone poorly or a company has just written down the value of its assets steeply and needs extra capital to stay afloat (see: investment banking industry). 
        
        \n- There is a liquidity crunch and the company cannot afford to pay its vendors or suppliers.`
    },
    {
        "id":79,
        "prompt":`What options are available to a distressed company that can't meet debt obligations?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"restructure",
        "solution":`- Refinance and obtain fresh debt/equity.
        \n- Sell the company (either as a whole or in pieces in an asset sale)
        \n- Restructure its financial obligations to lower interest payments/debt repayments, or issue debt with payment-in-kind interest to reduce the cash interest expense
        \n- File for bankruptcy and use that opportunity to obtain additional financing, restructure its obligations, and be freed of onerous contracts.`
    },{
        "id":80,
        "prompt":`What is payment-in-kind interest`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"restructure",
        "solution":`- is payment of interest w/ additional securities or equity instead of cash. OR the use of a good or service as payment instead of cash`
    },{
        "id":81,
        "prompt":`How are Restructuring deals different from other types of transactions?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"restructure",
        "solution":`-More complex, involve more parties, require more specialized/technical skills, and have to follow the Bankruptcy legal code

        \n -Unlike most standard M&A deals the negotiation extends beyond two "sides" - it's not just the creditors negotiating with the debtors, but also the different creditors negotiating with each other. 
        
        \n -Distressed sales can happen very quickly if the company is on the brink of bankruptcy, but those are different from Bankruptcy scenarios.`
    },{
        "id":82,
        "prompt":`How do commercial banks make money?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"fig",
        "solution":`- Gets money (deposits) from customers and earns small amount of interest on it
        \n- Collects all deposits and loans them in larger quantities and at higher interest rates to organizations that need to borrow money
        \n- Makes money on the interest rate spread (difference between what they earn on loans and what they issue)
        \n- Also makes money from non-interest sources of revenue such as credit card fees, asset management fees, investment banking, and securities trading`
    },{
        "id":83,
        "prompt":`How do insurance firms make money?`,
        "isMultiStep":false, "difficulty":"Easy",
        "topic":"fig",
        "solution":`- Collect PREMIUMS from customers who want to be protected in case of an accident
        \n- Pay out CLAIMS to customers if / when accident takes place
        \n- Collect premiums upfront and use this money (the "float") to make INVESTMENTS and earn interest / capital gains 
        \n- Insurance companies are typically unprofitable or marginally profitable from underwriting activities and become profitable from investing activities`
    },{
        "id":84,
        "prompt":`What are 5 key differences between commercial banks and normal companies?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"fig",
        "solutionSteps":[
            ["Balance Sheet First", "The Balance Sheet drives banks' performance, and you start the financial statements by projecting the Balance Sheet first."],
            ["Equity Value Only", "You cannot separate a bank's operating and financing activities as you can separate those of a traditional company. So, the concept of Enterprise Value does not apply, and you use Equity Value and Equity Value-based multiples instead."],
            ["Dividend Discount Models (DDM) in Place of DCFs", `"Free Cash Flow" doesn't mean anything for banks because the Change in Working Capital and CapEx do not represent reinvestment in the business. So, you use Dividends as a proxy for FCF, Cost of Equity instead of WACC, and the Dividend Discount Model instead of the Discounted Cash Flow analysis`],
            ["Regulation", `Must maintain minimum amounts of "capital" (Tangible Common Equity with a few modifications) at all times. These requirements constrain their growth.`],
            ["Different Valuation Multiples", `The Price / Book Value (P / BV), Price / Tangible Book Value (P / TBV), and Price / Earnings (P / E) multiples are all important because these firms are Balance Sheet-driven, and Interest is a huge part of their revenue.`]
        ]
    },{
        "id":85,
        "prompt":`How are the financial statements different for a commercial bank?`,
        "isMultiStep":true, "difficulty":"Easy",
        "topic":"fig",
        "solutionSteps":[
            ["Balance Sheet",`Loans on the Assets side and Deposits on the L&E side are the key drivers; there are new items, like the Allowance for Loan Losses (a contra-asset), and more categories for Investments and Securities; items common for normal companies, such as Inventory, may not be present. `],
            ["Income Statement",`Revenue is divided into Net Interest Income and Non-Interest Income; COGS do not exist; the Provision for Credit Losses is a major new expense; operating expenses are labeled Non-Interest Expenses. `],
            ["Cash Flow Statement",`The classifications are murkier; all changes in Balance Sheet items are still reflected here, and Net Income still flows in as the first item. New items include the add back for the Provision for Credit Losses and the Changes in Gross Loans and Deposits.`]
        ]
    },

]

export {data};