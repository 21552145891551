import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import useAuth from './useAuth';
import {getDatabase, off, onValue, ref, remove, set} from 'firebase/database';
import { data } from '../data/questions';


const QuestionActionsContext = createContext<any>(null);
export const QuestionActionsProvider = ({children}:{children:ReactNode}) => {
   
    const [saved, setSaved] = useState<{[key:number]:boolean}>({});
    const [status, setStatus ] = useState({});
   
    const {user} = useAuth();
    
    const fetchData =async () => {
        try {
            const fetchSaved = localStorage.getItem('saved');
            const fetchStatus = localStorage.getItem('status');
            
            const results = await Promise.all([
                fetchSaved ? await JSON.parse(fetchSaved) :{},
                fetchStatus ? await JSON.parse(fetchStatus) :{},
            ])
            setSaved(results[0]);
            setStatus(results[1]);
        } catch (error) {
            console.log({error})
        }
    }

    const markCorrect = (id:number) => {
        if (status[id as keyof typeof status]==="correct"){
            setStatus({...status, [id]:null})
            const temp = {...status};
            delete temp[id as keyof typeof temp];
            localStorage.setItem('status',JSON.stringify(temp))
        } else {
            setStatus({...status, [id]:"correct"});
            localStorage.setItem('status',JSON.stringify({...status, [id]:"correct"}))
        }
    }



    const markIncorrect = (id:number) => {
        if (status[id as keyof typeof status]==="incorrect"){
            setStatus({...status, [id]:null})
            const temp = {...status};
            delete temp[id as keyof typeof temp];
            localStorage.setItem('status',JSON.stringify(temp))
        } else {
            setStatus({...status, [id]:"incorrect"});
            localStorage.setItem('status',JSON.stringify({...status, [id]:"incorrect"}))
        }
    }



    const markSaved = (id:number) => {
        console.log(saved[id as keyof typeof saved])
        if (saved[id as keyof typeof saved]){
            setSaved({...saved, [id]:false})
            const temp = {...saved};
            delete temp[id as keyof typeof temp];
            localStorage.setItem('saved',JSON.stringify(temp))
        } else {
            setSaved({...status, [id]:true});
            localStorage.setItem('saved',JSON.stringify({...saved, [id]:true}))
        }
    }


    
    useEffect(()=>{
        if (!user) {
           fetchData()
        }
    },[user])

    return (
        <QuestionActionsContext.Provider value={{saved, status, markCorrect, markIncorrect,markSaved,}}>
            {children}
        </QuestionActionsContext.Provider>
    )
}

export default function useQuestionActions() {
    return useContext(QuestionActionsContext);
}

const AuthedQuestionActionsContext = createContext<any>(null);
export const AuthedQuestionActionsProvider = ({children}:{children:ReactNode}) => {
   
    const [saved, setSaved] = useState<{[key:number]:boolean}>({});
    const [status, setStatus ] = useState({});
   
    const {user} = useAuth();
    const database = getDatabase();

    const markCorrect = async(id:number) => {
        console.log({status})
        if (status[id as keyof typeof status]==="correct"){
            await remove(ref(database,`status/${user?.uid}/${id}`))
        } else {
            await set(ref(database,`status/${user?.uid}/${id}`),"correct")
        }
    }

    const markIncorrect = async(id:number) => {
        if (status[id as keyof typeof status]==="incorrect"){
            await remove(ref(database,`status/${user?.uid}/${id}`))
        } else {
            await set(ref(database,`status/${user?.uid}/${id}`),"incorrect")
        }
    }

    const markSaved = async(id:number) => {
        console.log(saved[id as keyof typeof saved])
        if (saved[id as keyof typeof saved]){
            await set(ref(database,`saved/${user?.uid}/${id}`),null)
        } else {
            await set(ref(database,`saved/${user?.uid}/${id}`),true)
        }
    }
    
    useEffect(()=>{
        onValue(ref(database, `status/${user?.uid}`), snapshot=>{
            if (snapshot.exists()){
                setStatus(snapshot.val())
            } else {
                setStatus({})
            }
        })
        return ()=>off(ref(database, `status/${user?.uid}`), "value")
    }, [database, user?.uid])

    useEffect(()=>{
        onValue(ref(database, `saved/${user?.uid}`), snapshot=>{
            if (snapshot.exists()){
                setSaved(snapshot.val())
            } else {
                setSaved({})
            }
        })
        return ()=>off(ref(database, `saved/${user?.uid}`), "value")
    }, [database, user?.uid])

    return (
        <AuthedQuestionActionsContext.Provider value={{saved, status, markCorrect, markIncorrect, markSaved}}>
            {children}
        </AuthedQuestionActionsContext.Provider>
    )
}

export function useAuthedQuestionActions() {
    return useContext(AuthedQuestionActionsContext);
}