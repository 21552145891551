import React, { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress, Dialog, DialogContentText, DialogTitle, Fab, FormControl, IconButton, InputLabel, MenuItem, Select } from "@mui/material";
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import CloseIcon from '@mui/icons-material/Close';
import { addDoc, collection, getFirestore } from "firebase/firestore";
import useAuth from "../../hooks/useAuth";

interface FormErrors {
    "bank"?:boolean, "location"?:boolean,"date"?:boolean, "position"?:boolean,
    "otherBank"?:boolean, "otherPosition"?:boolean, "otherLocation"?:boolean
}

interface SelectProps {
    setSubmitting: React.Dispatch<React.SetStateAction<"start" | "end" | null>>,
    setFormErrors: React.Dispatch<React.SetStateAction<FormErrors>>,
    formErrors:FormErrors
}

const SelectBank = ({formErrors, setFormErrors, setSubmitting}:SelectProps) => {
    const [otherBank, setOtherBank] = useState(false);
    return (
        <>
        <FormControl sx={{width:"100%", mt:2 }} error={formErrors.bank}>
            <InputLabel id="demo-simple-select-standard-label">Bank</InputLabel>
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                label="Bank"
                name="bank"
                onChange={(e)=>{
                    setFormErrors({...formErrors, "bank":false});
                    setSubmitting(null);
                    if (e.target.value==="other"){
                        setOtherBank(true)
                    } else {
                        setOtherBank(false)
                    }
                }}
            >
                <MenuItem value={"boa"}>Bank of America</MenuItem>
                <MenuItem value={"barclays"}>Barclays</MenuItem>
                <MenuItem value={"bmo"}>BMO Capital Markets</MenuItem>
                <MenuItem value={"bny"}>BNY Mellon</MenuItem>
                <MenuItem value={"centerview"}>Centerview Partners</MenuItem>
                <MenuItem value={"citi"}>Citigroup</MenuItem>
                <MenuItem value={"cs"}>Credit Suisse</MenuItem>
                <MenuItem value={"evercore"}>Evercore</MenuItem>
                <MenuItem value={"gs"}>Goldman Sachs</MenuItem>
                <MenuItem value={"guggenheim"}>Guggenheim Partners</MenuItem>
                <MenuItem value={"hl"}>Houlihan Lokey</MenuItem>
                <MenuItem value={"jpmorgan"}>JPMorgan Chase & Co</MenuItem>
                <MenuItem value={"lazard"}>Lazard</MenuItem>
                <MenuItem value={"merrill"}>Merrill</MenuItem>
                <MenuItem value={"moelis"}>Moelis & Company</MenuItem>
                <MenuItem value={"ms"}>Morgan Stanley</MenuItem>
                <MenuItem value={"pwp"}>Parella Wienberg Partners</MenuItem>
                <MenuItem value={"qatalyst"}>Qatalyst Partners</MenuItem>
                <MenuItem value={"ps"}>Piper Sandler</MenuItem>
                <MenuItem value={"rbc"}>RBC Capital Markets</MenuItem>
                <MenuItem value={"rothschild"}>Rothschild</MenuItem>
                <MenuItem value={"usbanc"}>US Bancorp</MenuItem>
                <MenuItem value={"wbc"}>William Blair & Company</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
            </Select>
            </FormControl>
            {otherBank && <TextField
            error={formErrors.otherBank}
                    margin="dense" label="Other Bank Name" variant="outlined" name="otherBank" multiline  fullWidth/>}
        </>
    )
}


const SelectBankLocation = ({formErrors, setFormErrors, setSubmitting}:SelectProps) => {
    const [otherLocation, setOtherLocation] = useState(false);
    return (
        <>
        <FormControl sx={{width:"100%", mt:2 }} error={formErrors.location}>
                    <InputLabel id="demo-simple-select-standard-label">Bank Location</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="location"
                        name="location"
                        onChange={(e)=>{
                            setFormErrors({...formErrors, "location":false})
                            setSubmitting(null);
                            if (e.target.value==="other"){
                                setOtherLocation(true)
                            } else {
                                setOtherLocation(false)
                            }
                        }}
                    >
                        
                        <MenuItem value={"boston"}>Boston, MA, USA</MenuItem>
                        <MenuItem value={"chicago"}>Chicago, IL, USA</MenuItem>
                        <MenuItem value={"houston"}>Houston, TX, USA</MenuItem>
                        <MenuItem value={"los_angeles"}>Los Angeles, CA, USA</MenuItem>
                        <MenuItem value={"menlo_park"}>Menlo Park, CA, USA</MenuItem>
                        <MenuItem value={"new_york"}>New York, NY, USA</MenuItem>
                        <MenuItem value={"san_francisco"}>San Francisco, CA, USA</MenuItem>
                        <MenuItem value={"santa_monica"}>Santa Monica, CA, USA</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
            </FormControl>
            {otherLocation && <TextField
                    error={formErrors.otherLocation}
                    margin="dense" label="Other Bank Location" variant="outlined" name="otherLocation" multiline  fullWidth/>}
        </>
    )
}

const SelectBankDate = ({formErrors, setFormErrors, setSubmitting}:SelectProps) => {
    return (
        <FormControl sx={{width:"100%", mt:2 }} error={formErrors.date}>
                    <InputLabel id="demo-simple-select-standard-label">Occurrence</InputLabel>
                    <Select
                        
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="date"
                        name="date"
                        onChange={()=>{
                            setFormErrors({...formErrors, "date":false})
                            setSubmitting(null);
                        }}
                    >
                        <MenuItem value={"su_2023"}>Summer 2023</MenuItem>
                        <MenuItem value={"sp_2023"}>Spring 2023</MenuItem>
                        <MenuItem value={"w_2022"}>Winter 2022</MenuItem>
                        <MenuItem value={"fa_2022"}>Fall 2022</MenuItem>
                        <MenuItem value={"su_2022"}>Summer 2022</MenuItem>
                        <MenuItem value={"sp_2022"}>Spring 2022</MenuItem>
                        <MenuItem value={"w_2021"}>Winter 2021</MenuItem>
                        <MenuItem value={"fa_2021"}>Fall 2021</MenuItem>
                        <MenuItem value={"su_2021"}>Summer 2021</MenuItem>
                        <MenuItem value={"sp_2021"}>Spring 2021</MenuItem>
                        <MenuItem value={"w_2020"}>Winter 2020</MenuItem>
                        <MenuItem value={"fa_2020"}>Fall 2020</MenuItem>
                        <MenuItem value={"su_2020"}>Summer 2020</MenuItem>
                        <MenuItem value={"sp_2020"}>Spring 2020</MenuItem>
                        <MenuItem value={"w_2019"}>Winter 2019</MenuItem>
                        <MenuItem value={"fa_2019"}>Fall 2019</MenuItem>
                        <MenuItem value={"su_2019"}>Summer 2019</MenuItem>
                        <MenuItem value={"sp_2019"}>Spring 2019</MenuItem>
                    </Select>
            </FormControl> 
    )
}

const SelectBankPosition = ({formErrors, setFormErrors, setSubmitting}:SelectProps) => {
    const [otherPosition, setOtherPosition] = useState(false);
    return (
        <>
            <FormControl sx={{width:"100%", mt:2 }} error={formErrors.position}>
                <InputLabel id="demo-simple-select-standard-label">Position</InputLabel>
                <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="position"
                    name="position"
                    onChange={(e)=>{
                        setFormErrors({...formErrors, "position":false})
                        setSubmitting(null);
                        if (e.target.value==="other"){
                            setOtherPosition(true)
                        } else {
                            setOtherPosition(false)
                        }
                    }}
                >
                    <MenuItem value={"analyst"}>Analyst</MenuItem>
                    <MenuItem value={"associate"}>Associate</MenuItem>
                    <MenuItem value={"vp"}>Vice President</MenuItem>
                    <MenuItem value={"director"}>Principal/Director</MenuItem>
                    <MenuItem value={"md"}>Managing Director</MenuItem>
                    <MenuItem value={"other"}>Other</MenuItem>
                </Select>
            </FormControl> 
            {otherPosition && <TextField
                    error={formErrors.otherPosition}
                    margin="dense" label="Other Position" variant="outlined" name="otherPosition" multiline  fullWidth/>}
        </>
    )
}

export default function NewQuestionForm({id}:{id:number}) {
    const [open, setOpen] = useState(false);
    const [formErrors, setFormErrors] = useState<FormErrors>({
        bank:false,
        location:false,
        date:false,
        position:false,
        otherBank:false,
        otherLocation:false,
        otherPosition:false,
    })
    const [submitting, setSubmitting] = useState<"start"|"end"|null>(null);
    const {user} = useAuth();
    const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        try {
            setSubmitting("start")
            event.preventDefault();
            
            const data = new FormData(event.currentTarget);
            const bank= data.get('bank') as string;
            const location= data.get('location') as string;
            const date= data.get('date') as string;
            const position = data.get('position') as string;
            const otherBank = data.get('otherBank');
            const otherLocation = data.get('otherLocation');
            const otherPosition = data.get('otherPosition');
            const errors :FormErrors = {};
            if (!bank){
                errors["bank"] = true;
            } if (bank==="other" && !otherBank) {
                errors["otherBank"] = true;
            }
            
            if (!location) {
                errors["location"] = true;
            } if (location==="other" && !otherLocation) {
                errors["otherLocation"] = true;
            }
            
            if (!date) {
                errors["date"] = true;
            } 
            
            if (!position) {
                errors["position"] = true;
            } if (position==="other" && !otherPosition) {
                errors["otherPosition"] = true;
            }
            if (Object.keys(errors).length >0) {
                setSubmitting(null)
                setFormErrors(errors);
                
                return;
            } 
            const firestore = getFirestore();
            await addDoc(collection(firestore,"interview"), {
                bank, 
                location, 
                date, 
                position, 
                id,
                uid:user ? user.uid : null
            })
            setSubmitting("end")
            setOpen(false);
        } catch (error) {
            console.log({error})
            setSubmitting(null);
        } 
    };



    return (
        <>
        <Button sx={{fontSize:"12px", color:"#21212199", textTransform:"lowercase", textDecoration:"underline"}} size="small" onClick={()=>setOpen(true)}>See this Question Before?</Button>
        <Dialog open={open} onClose={()=>{}}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                marginBottom:8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <DynamicFormIcon />
            </Avatar>
          
            <Typography component="h1" variant="h5">
                Submit Question
            </Typography>
            <IconButton sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }} onClick={()=>setOpen(false)}><CloseIcon/></IconButton>
         
            
            <DialogContentText>See this question before in an interview?</DialogContentText>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, }} >
                <SelectBank formErrors={formErrors} setFormErrors={setFormErrors} setSubmitting={setSubmitting} />
                <SelectBankLocation formErrors={formErrors} setFormErrors={setFormErrors} setSubmitting={setSubmitting}/>
                <SelectBankDate formErrors={formErrors} setFormErrors={setFormErrors} setSubmitting={setSubmitting}/>
                <SelectBankPosition formErrors={formErrors} setFormErrors={setFormErrors} setSubmitting={setSubmitting}/>
            
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                Submit
                </Button>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    {submitting==="start" && <CircularProgress size={20}/>}
                    {submitting==="end" && <Typography variant="body2" color="primary">Submitted</Typography>}      
                </div>
            </Box>
            
            </Box>
            
        </Container>
        </Dialog>
        </>
    );
}

type NewQuestionFormErrors =FormErrors &{
    prompt?:boolean
} 

export function SubmitNewQuestionForm() {
    const [open, setOpen] = useState(false);
    const [formErrors, setFormErrors] = useState<NewQuestionFormErrors>({
        bank:false,
        prompt:false,
        location:false,
        date:false,
        position:false,
        otherBank:false,
        otherPosition:false,
        otherLocation:false
    })
    const [submitting, setSubmitting] = useState<"start"|"end"|null>(null);
    const {user} = useAuth();
    const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        try {
            setSubmitting("start")
            event.preventDefault();
            
            const data = new FormData(event.currentTarget);
            const bank= data.get('bank') as string;
            const location= data.get('location') as string;
            const date= data.get('date') as string;
            const position = data.get('position') as string;
            const prompt = data.get('prompt') as string
            const solution = data.get('solution') as string
            const otherBank = data.get('otherBank') as string;
            const otherLocation = data.get('otherLocation') as string;
            const otherPosition = data.get('otherPosition') as string;

            const errors :NewQuestionFormErrors = {};
            if (!bank){
                errors["bank"] = true;
            } if (bank==="other" && !otherBank) {
                errors["otherBank"] = true;
            } if (!location) {
                errors["location"] = true;
            } if (location==="other" && !otherLocation) {
                errors["otherLocation"] = true;
            } if (!date) {
                errors["date"] = true;
            } if (!position) {
                errors["position"] = true;
            } if (position==="other" && !otherPosition) {
                errors["otherPosition"] = true;
            } if (!prompt) {
                errors["prompt"] = true;
            }
            if (Object.keys(errors).length >0) {
                setSubmitting(null)
                setFormErrors(errors);
                
                return;
            } 
            const firestore = getFirestore();
            const upload =  {
                bank, 
                location, 
                date, 
                position, 
                uid:user ? user.uid : null,
                prompt,
            }
            await addDoc(collection(firestore,"newQuestion"), solution ? {...upload, solution} : upload )
            setSubmitting("end")
            setOpen(false);
        } catch (error) {
            console.log({error})
            setSubmitting(null);
        } 
    };



    return (
        <>
        <Fab color="secondary" sx={{position:"absolute", mx:{xs:2, md:4, lg:6}, my:{xs:2, md:4, lg:6}, right:0, bottom:0}} size={"medium"} onClick={()=>setOpen(true)}>
            <DynamicFormIcon />
        </Fab>
        <Dialog open={open} onClose={()=>{}}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                marginBottom:8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <DynamicFormIcon />
            </Avatar>
            
            <Typography component="h1" variant="h5">
                Submit Question
            </Typography>
            <IconButton sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }} onClick={()=>setOpen(false)}><CloseIcon/></IconButton>
            
            
            <DialogContentText>See this question before in an interview?</DialogContentText>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, }} >
                <TextField error={formErrors.prompt} 
                    onChange={(e)=>{
                        setFormErrors({...formErrors, "prompt":false});
                        setSubmitting(null)
                    }} 
                    margin="dense" label="Question Prompt" variant="outlined" name="prompt" multiline  fullWidth/>
                <TextField error={formErrors.prompt} 
                    onChange={(e)=>{
                        setFormErrors({...formErrors, "prompt":false});
                        setSubmitting(null)
                    }} 
                    margin="dense" label="Question Solution (Optional)" variant="outlined" name="prompt" multiline  fullWidth/>
                <SelectBank formErrors={formErrors} setFormErrors={setFormErrors} setSubmitting={setSubmitting} />
                <SelectBankLocation formErrors={formErrors} setFormErrors={setFormErrors} setSubmitting={setSubmitting}/>
                <SelectBankDate formErrors={formErrors} setFormErrors={setFormErrors} setSubmitting={setSubmitting}/>
                <SelectBankPosition formErrors={formErrors} setFormErrors={setFormErrors} setSubmitting={setSubmitting}/>
            
                <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                Submit
                </Button>
                <div style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                    {submitting==="start" && <CircularProgress size={20}/>}
                    {submitting==="end" && <Typography variant="body2" color="primary">Submitted</Typography>}      
                </div>
            </Box>
            
            </Box>
            
        </Container>
        </Dialog>
        </>
    );
}