import { Alert, Snackbar } from "@mui/material";

interface SnackBarProps {
    msg:string | undefined,
    open:boolean,
    handleClose:Function,
    severity?: "success" | "error"
}

export const SnackBar = (props: SnackBarProps) => {
    const {msg, open, handleClose, severity} = props;
    return (
        <Snackbar
            anchorOrigin={{vertical:"top", horizontal:"center"}}
            open={open}
            onClose={()=>handleClose(false)}
            message={msg}
            key={"top-center"}
        >
            <Alert severity={severity} sx={{ width: '100%' }}>
                {msg}
            </Alert>
        </Snackbar>
    )
}

