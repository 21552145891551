import React, { useEffect, useState } from "react";
import { FlashCard } from "../components/flashcard"
import {  MobileStepper,  Stack, Button, useTheme, Typography, Chip, Divider, Checkbox, FormControlLabel  } from "@mui/material";
import { NavigateNext as NextIcon, NavigateBefore as PrevIcon, Shuffle as ShuffleIcon } from "@mui/icons-material"
import useQuestions, { QuestionsProvider } from "../hooks/useQuestions";
import useQuestionActions, { useAuthedQuestionActions } from "../hooks/useQuestionActions";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { SubmitNewQuestionForm } from "../components/forms/SubmitNewQuestionForm";


export const topics = [{label:"All", value:"all"},
{label:"Accounting", value:"accounting"},
{label:"Enterprise & Equity", value:"ene"},
{label:"DCF", value:"dcf"},
{label:"Financial Institutions", value:"fig"},
{label:"LBO", value:"lbo"},
{label:"M&A", value:"mna"},
{label:"Valuation", value:"valuation"},
{label:"Restructure", value:"restructure"}];

interface PracticeState {
    randomize:boolean;
    topics: Array<string> | null;
    bySaved: boolean,
    byStatus?:"correct" | "incorrect" | null,
    index:number
}

function shuffle(oldArray:Array<object>){
    const array = [...oldArray];
    let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
    while (currentIndex !== 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
}





const FlashCardNav = (props: any)=> {
    const {questions} = props;
    const [index, setIndex] = useState(0);
    const [numQuestions, setNumQuestions] = useState(questions.length)
    
    useEffect(()=>{
        setNumQuestions(questions.length);
    },[questions, index])

    useEffect(()=>{setIndex(0)},[numQuestions])

    const Stepper = ( ) => {
        const theme = useTheme();
        
        const handleNext = () => setIndex(index + 1);
        const handleBack = () => setIndex(index - 1);
    
        return (
            <MobileStepper
            variant="progress"
            steps={numQuestions}
            position="static"
            activeStep={index}
            sx={{ flexGrow: 1, backgroundColor:"inherit" , m:"24px auto"}}
            nextButton={
                <Button size="small" onClick={handleNext} disabled={index === numQuestions - 1}>
                Next
                {theme.direction === 'rtl' ? ( <NextIcon />) : (<NextIcon /> )}
                </Button>
            }
            backButton={
                <Button size="small" onClick={handleBack} disabled={index === 0}>
                {theme.direction === 'rtl' ? ( <PrevIcon /> ) : (
                    <PrevIcon />
                )} Back
                </Button>
            } />
        ); 
    }
    return (
        <>
            <FlashCard question={questions.length <= index ? questions[0]: questions[index]} />    
                        
            <Stepper/>
        </>
    )
}




export const PracticePageBody = ({saved, status}:{saved:{[key:number]:boolean}, status:{[key:number]:string}}) => {
    const [practiceState, setPracticeState] = useState<PracticeState>({
        randomize:false, 
        topics:null,
        bySaved: false,
        byStatus:null,
        index:0,
    });
    const questionsHook = useQuestions();
    const {questions, setQuestions} = questionsHook || {questions:[], setQuestions:()=>{}};
    const [filteredAndSortedData, setFilteredAndSortedData] = useState(questions);
   

    function filterBySaved(questions:Array<any>, bySaved:boolean){
        if (bySaved){
            return questions.filter(q => saved[q.id] ? true : false) 
        }
        return questions;
    }
    
    
    function filterByTopics(questions:Array<any>, topics:null | Array<string>){
        if (!topics){
            return questions;
        }
        return questions.filter((question) => 
            topics && topics.includes(question.topic) && question
        )
    }
    
    function filterByStatus(questions:Array<any>, byStatus?:null | "correct" | "incorrect"){
        if (byStatus){
            return questions.filter(q => status[q.id]===byStatus) 
        }
        return questions;
    }
    
    
    function filterQuestions(questions:Array<any>, state: PracticeState){
        const filteredByTopics = filterByTopics(questions, state.topics);
        const filteredByTopicsAndSaved = filterBySaved(filteredByTopics, state.bySaved); 
        const filteredByStatus = filterByStatus(filteredByTopicsAndSaved, state.byStatus)
        return filteredByStatus;
    }



    const TopicFilters = () => {
        const selectTopics = (value:string) => {
            if (value ==="all"){
                setPracticeState({...practiceState, topics:null})
            } else {
                Array.isArray(practiceState.topics) ?
                    setPracticeState({...practiceState, topics:[...practiceState.topics, value]}) :
                    setPracticeState({...practiceState, topics:[value]})
            }
        }

        const removeTopic = (value:string) => {
            if (Array.isArray(practiceState.topics)){
                practiceState.topics.length === 1 ?
                    setPracticeState({...practiceState, topics:null}) :
                    setPracticeState({...practiceState, topics:practiceState.topics.filter((v)=>v !== value) })
            }
        }
        return (
            <Stack  direction="row" sx={{m:"24px auto", gap:1}} flexWrap="wrap">
                { topics.map(({label, value})=>(
                    Array.isArray(practiceState.topics) ? 
                        practiceState.topics.includes(value) ? 
                        <Chip clickable={true} label={label} 
                            variant= "filled"  key={value}
                            onClick={()=>selectTopics(value)} onDelete={()=>removeTopic(value)}/> :
                        <Chip clickable={true} label={label} 
                            variant={"outlined"} 
                            onClick={()=>selectTopics(value)} key={value}/> :
                        <Chip clickable={true} label={label} 
                            variant={value==="all"? "filled":"outlined"} 
                            onClick={()=>selectTopics(value)} key={value}/> ))}
            </Stack>
        )
    }

    const Randomize = () => {
        return (
            <Stack  direction="row" sx={{m:"24px auto"}}>
                {
                    practiceState.randomize ? (
                    <Button sx={{gap:1}} variant="outlined" style={{backgroundColor:"rgba(25, 118, 210, 0.04)", border:"1px solid #1976d2"}}
                        onClick={()=>setPracticeState({...practiceState, randomize:false})}>
                        <ShuffleIcon/> Randomize
                    </Button> ) : (
                    <Button sx={{gap:1}} variant="text" onClick={()=>setPracticeState({...practiceState, randomize:true})}>
                        <ShuffleIcon/> Randomize
                    </Button> )
                }
            </Stack>
        )
    }

    useEffect(()=>{
        const filteredSortedQuestions = practiceState.randomize ? 
        shuffle(filterQuestions(questions, practiceState)) 
        : filterQuestions(questions, practiceState);
        setFilteredAndSortedData(filteredSortedQuestions);
    }, [practiceState, questions])
  
    
        
    return (
        <>
            <TopicFilters/>
            <Divider/>
            <Stack direction={"row"}>
                <FormControlLabel
                    value="saved"
                    control={<Checkbox checked={practiceState.bySaved} 
                        onChange={()=>setPracticeState({...practiceState, bySaved:!practiceState.bySaved})} 
                    />}
                    label="Saved"
                    labelPlacement="end"
                />
                <Button 
                    variant={practiceState.byStatus==="correct" ? "contained" :"text"}
                    sx={{ml:1}} startIcon={<CheckIcon/>} color="success"
                    onClick={()=>setPracticeState({...practiceState, byStatus:practiceState.byStatus==="correct" ? null : "correct"})}
            >Correct</Button>
            <Button 
                variant={practiceState.byStatus==="incorrect" ? "contained" :"text"}
                sx={{ml:1}} startIcon={<CloseIcon/>} color="error"
                onClick={()=>setPracticeState({...practiceState, byStatus:practiceState.byStatus==="incorrect" ? null : "incorrect"})}
            >Incorrect</Button>
            </Stack>
            <Randomize />
            {filteredAndSortedData.length === 0 ? null : 
            <FlashCardNav questions={filteredAndSortedData} />}
            <SubmitNewQuestionForm/>
        </>
    )
}

export const PracticePage = () => {
    const {saved, status} = useQuestionActions();
    return (
     
            <QuestionsProvider>
                <Typography variant="h4" sx={{m:"24px auto"}}>Investment Banking Practice</Typography>
                <PracticePageBody saved={saved} status={status}/>
            </QuestionsProvider>
      
    )
}

export const AuthedPracticePage =() => {
    const {saved, status} = useAuthedQuestionActions();
    return (
            <QuestionsProvider>
                <Typography variant="h4" sx={{m:"24px auto"}}>Investment Banking Practice</Typography>
                <PracticePageBody saved={saved} status={status}/>
            </QuestionsProvider>
       
    )
}