import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import {User, getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, 
    setPersistence, browserSessionPersistence, onAuthStateChanged, sendPasswordResetEmail, confirmPasswordReset} from 'firebase/auth';
import LoadingPage from "../routes/LoadingPage";

const AuthContext = createContext<{
    user:User|null, authError:any|null,
    signin:(email:string, password:string, persist:string|null)=>void,
    signout:() => Promise<void>,
    passwordReset: (email: string) => Promise<void>,
    signup: (email: string, password: string,checkPassword:string, persist:string|null) => void,
    confirmThePasswordReset: (oobCode: string, newPassword: string) => Promise<void>
}>({user:null, authError:null,
    signin:(email:string, password:string)=>{},
    signout:() => {return Promise.resolve()},
    signup: (email: string, password: string) => {},
    passwordReset: (email: string) => {return Promise.resolve()},
    confirmThePasswordReset: (oobCode: string, newPassword: string) => {return Promise.resolve()},
});

export const AuthProvider = ({children}:{children:ReactNode}) =>{
    const authed = getAuth();
    
    const [user, setUser] = useState<User | null>(null);
    const [authError, setAuthError] = useState<any>(null);
    const [initLoading, setInitLoading] = useState(true);
    console.log({user})
    const persistSession = async(persist:string|null) => {
        try {
             if (persist==="remember"){
                await setPersistence(authed, browserSessionPersistence);
             }
        } catch (error) {
            console.log({error});
        }
    }

    const signin = (email:string, password:string, persist:string|null)=>{
        persistSession(persist)
        .then(()=>signInWithEmailAndPassword(authed, email, password))
        .then((userCredential)=>{
            console.log({userCredential})
            if (userCredential.user){
                console.log("setting user credential")
                setUser(userCredential.user);
            } else {
                console.log("setting user credential to NULL")
                setUser(null)
            }
            setAuthError(null)
        })
        .catch((error)=>{
            setAuthError(error)
        })
    }

    const signup = (email:string, password:string, checkPassword:string, persist:string|null) =>{
        console.log({checkPassword, password})
        if (password.length >0 && checkPassword.length >0 && password===checkPassword){
            persistSession(persist)
            .then(()=>createUserWithEmailAndPassword(authed, email, password))
            .then((userCredential) => {
                
                if (userCredential.user){
                    console.log("setting user credential")
                    setUser(userCredential.user);
                } else {
                    console.log("setting user credential to NULL")
                    setUser(null)
                }
                setAuthError(null)
            })
            .catch((error) => {
                setAuthError(error);
                // ..
            });
        } else {
            setAuthError({message:"Password mismatch"})
        }
        
    }

    const passwordReset = async(email:string) => {
        try {
            await sendPasswordResetEmail(authed, email)
        } catch (error) {
            console.log({error})
        }
    }

    const confirmThePasswordReset = async (
        oobCode:string, newPassword:string
      ) => {
        if(!oobCode && !newPassword) return;
        
        return await confirmPasswordReset(authed, oobCode, newPassword)
      }

    const signout = async()=>{
        try {
            await signOut(authed);
            setUser(null);
        } catch (error) {
            console.log({signOutError:error})
        }
    }

   useEffect(()=>onAuthStateChanged(authed, (user)=>{
        if (user){
            setUser(user)
        } else {
            setUser(null)
        }
        setInitLoading(false)
    }),[authed])
    
    if (initLoading){
        return (<LoadingPage/>)
    }

    return (
        <AuthContext.Provider value={{user, authError, passwordReset, confirmThePasswordReset, signin, signout, signup}}>
            {children}
        </AuthContext.Provider>
    )
}



export default function useAuth(){
    return useContext(AuthContext);
}